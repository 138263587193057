.progress-wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.5rem;

  &--full {
    .progress-target {
      &__star {
        .icon {
          &:before {
            content: '\f4b3';
          }
        }
      }
      &__label {
        background: #ffa511;
        color: #ffffff;
      }
    }
  }
}

.progress-target {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  &__star {
    font-size: 24px;
    color: #fed230;
    margin-bottom: 4px;
  }
  &__label {
    background: #fff;
    border: 1px solid #ffa511;
    color: $base-blue;
    padding: 2px 10px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
  }
}

.progress {
  flex: 1 0 100%;
  width: 100%;
  &__bar {
    background-image: linear-gradient(
      135deg,
      #fce38a 0%,
      #fce08a 5%,
      #fbdc89 9%,
      #fbd789 12%,
      #fad088 15%,
      #f9c887 19%,
      #f8bf86 22%,
      #f7b585 27%,
      #f6ab84 32%,
      #f5a183 38%,
      #f59783 46%,
      #f48f82 56%,
      #f38881 68%,
      #f38381 83%,
      #f38181 100%
    );
    background-color: #f38181;
    box-shadow: none;
    font-size: 11px;
    letter-spacing: 0.5px;
    font-weight: bold;
    text-align: right;
    padding: 0.25rem;
  }
}

.tier-bar {
  position: relative;
}
.progress-cclub-fake {
  min-width: 30px;
  width: 3%;
  //border-top-left-radius: 20px;
  //border-bottom-left-radius: 20px;
  position: absolute;
  height: 30px;
  margin-left: 19px;
  overflow: visible;
  .progress-bar {
    //border-top-left-radius: 20px;
    //border-bottom-left-radius: 20px;
  }
}

.progress-cclub-fake-right {
  min-width: 30px;
  width: 3%;
  //border-top-right-radius: 20px;
  //border-bottom-right-radius: 20px;
  position: absolute;
  height: 30px;
  margin-left: 19px;
  float: right;
  overflow: visible;
  position: absolute;
  right: -3%;
  .progress-bar {
    width: 100%;
    //border-top-right-radius: 20px;
    //border-bottom-right-radius: 20px;
    background: #808487;
  }
}

.progress-cclub {
  height: 30px;
  width: 97%;
  margin-left: 44px;
  position: relative;
  overflow: visible;
  float: left;
}

.pb-label {
  position: absolute;
  top: 34px;
  color: black;
  text-align: left;
  left: 0px;
  width: 76%;
  white-space: nowrap;
  overflow: visible;
  &.top {
    top: -24px;
  }
}

.tick-label {
  position: relative;
  margin: 0px 5px;
  color: #333;
  font-weight: 400;
  background: #fff;
  padding: 4px;
  border: 1px solid #bd9535;
  &--pull-on-left {
    right: 0;
  }
}

.tick-before {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 50px;
  border-left: 1px solid #000;
  display: flex;
  align-items: flex-end;
}
.tick-after {
  position: absolute;
  top: 0px;
  width: 1px;
  height: 100px;
  border-left: 1px dashed #000;
  display: flex;
  align-items: flex-end;
}
