.modal-hero-image {
  height: 195px;
  background: center no-repeat;
  background-size: cover;
  &--small {
    height: 100px;
    background-position: 0 0;
  }
  &__close {
    margin: 4px;
    color: white;
    text-shadow: none;
    width: 30px;
    &:active {
      color: white;
    }
  }
}