#cruise-itinerarynew {
  .map-wrapper {
    /*background: linear-gradient(0deg, #E6E9F0 0%, #EEF1F5 100%);*/
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    padding: 15px 0;
    .map {
      animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      background: center no-repeat;
      background-size: cover;
      height: 300px;
      //width: 300px;
      border-radius: 0;
      border: 1px double #d0e3f1;
      @media only screen and (min-width: 1200px) {
        height: 300px;
        //width: 300px;
      }
    }
  }
}
