/*--------------------------------

CostaFacilities icon font
Generated using nucleoapp.com

-------------------------------- */

$cc-icon-font-path: 'fonts' !default;

@font-face {
  font-family: 'CostaFacilities';
  src: url('#{$cc-icon-font-path}/CostaFacilities.eot');
  src:
    url('#{$cc-icon-font-path}/CostaFacilities.eot') format('embedded-opentype'),
    url('#{$cc-icon-font-path}/CostaFacilities.woff2') format('woff2'),
    url('#{$cc-icon-font-path}/CostaFacilities.woff') format('woff'),
    url('#{$cc-icon-font-path}/CostaFacilities.ttf') format('truetype'),
    url('#{$cc-icon-font-path}/CostaFacilities.svg') format('svg');
}

/* base class */
.cc-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'CostaFacilities';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.cc-icon-sm {
  font-size: 0.8em;
}

.cc-icon-lg {
  font-size: 1.2em;
}

/* size examples - absolute units */
.cc-icon-16 {
  font-size: 16px;
}

.cc-icon-32 {
  font-size: 32px;
}

/* rotate the icon infinitely */
.cc-icon-is-spinning {
  animation: cc-icon-spin 1s infinite linear;
}

@keyframes cc-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* transform */
.cc-icon-rotate-45 {
  transform: rotate(45deg);
}

.cc-icon-rotate-90 {
  transform: rotate(90deg);
}

.cc-icon-rotate-180 {
  transform: rotate(180deg);
}

.cc-icon-rotate-270 {
  transform: rotate(270deg);
}

.cc-icon-flip-y {
  transform: scaleY(-1);
}

.cc-icon-flip-x {
  transform: scaleX(-1);
}

/* icons */
$icons: (
  'bed-convertible': 'ea01',
  'bed-convertible-2': 'ea02',
  'bed-double': 'ea03',
  'bed-double-2': 'ea04',
  'bed-single': 'ea05',
  'bed-single-2': 'ea06',
  'cabins': 'ea07',
  'convertible-bed': 'ea08',
  'cradle-bed': 'ea09',
  'cradle-bed-2': 'ea0a',
  'disable': 'ea0b',
  'excursions': 'ea0c',
  'itinerary': 'ea0d',
  'sofa': 'ea0e',
  'sofa-2': 'ea0f',
);

@function unicode($str) {
  @return unquote('"\\#{$str}"');
}

@each $name, $code in $icons {
  .cc-facilities-icon-#{'' + $name}::before {
    content: unicode($code);
  }
}
