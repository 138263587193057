.price-details-table {
  &-container {
    border-radius: 0;
    margin: 1rem 0;
    overflow: hidden;
    padding: 0 1rem;
    box-shadow: 0 6px 7px $box-shadow;
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    border: 1px solid $light-grey;
  }
  > .panel-body {
    padding-bottom: 0;
  }
  .guest-price-wrapper {
    border: 1px solid lighten($light-blue, 15%);
    overflow-x: hidden;
    margin: .5rem auto;
    box-shadow: 0 0 5px lighten($light-blue, 15%);
    border-radius: 0;
  }
  &__row {
    flex-flow: row wrap;
    align-items: center;
    white-space: normal;
    padding: .5rem 0;
    border-bottom: 1px solid $light-grey;
    &--bigger-gap {
      padding: 1rem 0;
    }
    &--font-small {
      font-size: 0.85rem;
    }
    &--grey {
      background-color: $light-grey;
      color: $dark-grey;
    }
    &--yellow {
      background-color: lighten($yellow, 35%);
      //border-color: lighten($light-yellow, 20%);
      color: $dark-gold;
    }
    &--blue {
      background-color: lighten($light-blue, 15%);
      color: $dark-blue;
    }
    &--guest-total {
      border-color: lighten($light-blue, 5%);
      border-width: 2px;
    }
    &--bold {
      font-weight: bold;
    }
    &--indent-1x {
      > [class*='col-']:first-child {
        padding-left: 4% !important;
      }
    }
    &--indent-2x {
      > [class*='col-']:first-child {
        padding-left: 6% !important;
      }
    }
    &--indent-3x {
      > [class*='col-']:first-child {
        padding-left: 8% !important;
      }
    }
  }
  &__total-cabin {
    background: #fafafa;
    border-top: 1px solid #fff;
  }
  &__grand-total {
    background: #f6f6f6;
    border-top: 1px solid $light-grey;
    font-size: 1rem;
    letter-spacing: 0.25px;
    padding: 1rem 0;
    b {
      text-transform: uppercase;
    }
  }
  &__header {
    background-color: $base-blue;
    color: $white;
    padding: 1rem 0;
    text-transform: uppercase;
    border-bottom-width: 0;
    &--gift-card {
      background-color: #fff;
      color: $blue;
      border-radius: 0;
    }
    &--gift-card-mini {
      padding: .5rem 0;
      background-color: #fff;
      color: $blue;
      border-radius: 0;
    }
  }
}
