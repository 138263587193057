/* BOTTONI COSTA */
.btn-primary,
.btn-secondary,
.btn-white {
  position: relative;
  &:not(.btn-link) {
    &.disabled,
    &[disabled] {
      border: 1px dashed $gray-600;
      background-color: $gray-200;
      color: $gray-600;
      &:before {
        transition: 0.66s all ease;
        inset: -1px calc(100% + 1px) -1px -1px;
        opacity: 1;
      }
    }
    &:before {
      transition: 0.5s all ease;
      position: absolute;
      inset: -1px -1px -1px -1px;
      opacity: 1;
      content: '';
      z-index: -2;
    }
  }
}
/* BOTTONI COSTA END*/
