.article {
  padding-bottom: 15px;
  margin-bottom: 15px;
  &__thumb {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    &:before {
      content: '';
      display: block;
    }
  }
  &__intro-image {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &__body {
    padding: 10px 15px;
  }
  &__release-date {
    color: $base-grey;
    font-size: 0.9em;
  }
  &__icon {
    margin-right: 7px;
  }
  &__title {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
    margin-top: 10px;
    @media only screen and (min-width: 992px) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    }
  }
  &__paragraph {
    margin-bottom: 20px;
  }
  &__cta {
  }
}
/* LIST */

/* LIST ASIDE */
.aside-article {
  margin-bottom: 15px;
  grid-template-columns: 100%;
  grid-template-rows: 330px auto;
  gap: 15px;
}

.qr-code {
  &__image {
    margin: 25px 0;
  }
}
/* LIST ASIDE END */

/* ARTICLE LIST */
.article-list {
  background: $white;
  margin-bottom: 15px;
  border-radius: 0;
  box-shadow: $box-shadow;
  &:hover,
  &:focus {
    box-shadow: $box-shadow-lg;
    transform: translateY(-5px);
  }
  &:active {
    box-shadow: $box-shadow;
    transform: translateY(-2px);
  }
  &__thumb {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    transition: all 250ms;
    background-color: $light-grey;
    box-shadow: none;
    border: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    &:before {
      content: '';
      display: block;
    }
    &:hover,
    &:focus {
    }
    &:active {
    }
  }
  &__intro-image {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &__body {
    padding: 10px 15px;
  }
  &__release-date {
    color: $base-grey;
    font-size: 0.9em;
  }
  &__icon {
    margin-right: 7px;
  }
  &__title {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
    margin-top: 10px;
    @media only screen and (min-width: 992px) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    }
  }
  &__intro {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 4, $bgColor: white);
  }
  &__cta {
    margin-bottom: 10px;
  }
}
/* ARTICLE LIST END */

/* SIDEBAR ENTRY */
.aside-article-list {
  padding: 10px 0;
  @media only screen and (min-width: 992px) {
    border: 0;
    &:not(:last-child) {
      border-bottom: 1px solid $base-grey;
    }
  }
  &__thumb {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    transition: all 250ms;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px #d1cccc;
    &:before {
      content: '';
      display: block;
    }
  }
  &__intro-image {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  &__body {
  }
  &__release-date {
    font-size: 0.9em;
    color: $base-grey;
  }
  &__icon {
    margin-right: 7px;
  }
  &__title {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    margin-top: 5px;
    @media only screen and (min-width: 992px) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    }
  }
  &__intro {
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    @media only screen and (min-width: 992px) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
    }
  }
  &__arrow {
    align-self: center;
    justify-self: center;
    a {
      color: $medium-yellow;
      transition: color ease-out 0.33s;
      &:hover {
        color: darken($medium-yellow, 10%);
        transition: color ease-in 0.33s;
      }
    }
  }
}
/* SIDEBAR ENTRY END */
/* LIST END */

.img {
  &-article-thumb {
    float: right;
    padding: 5px 10px;
  }
}
