@import 'src/styles/variables';

$cellHeight: 60px;
$smallCellHeight: 36px;
$cellWidth: 14.28%;

@mixin calendarAnimation($height) {
  //next month animation
  &.forward-by-4 {
    &.ng-enter,
    &.ng-enter-prepare {
      //       transform: translateY($height*4)
      top: $height * 4;
    }
    &.ng-enter-active,
    &.ng-leave {
      //       transform: translateY(0px)
      top: 0;
    }
    &.ng-leave-active {
      //       transform: translateY(-$height*4)
      top: -$height * 4;
    }
  }
  &.forward-by-5 {
    &.ng-enter,
    &.ng-enter-prepare {
      //       transform: translateY($height*5)
      top: $height * 5;
    }
    &.ng-enter-active,
    &.ng-leave {
      //       transform: translateY(0px)
      top: 0;
    }
    &.ng-leave-active {
      //       transform: translateY(-$height*5)
      top: -$height * 5;
    }
  }

  //previous month animation
  &.back-by-4 {
    &.ng-enter,
    &.ng-enter-prepare {
      //       transform: translateY(-$height*4);
      top: -$height * 4;
    }
    &.ng-enter-active,
    &.ng-leave {
      //       transform: translateY(0px);
      top: 0px;
    }
    &.ng-leave-active {
      //       transform: translateY($height*4);
      top: $height * 4;
    }
  }
  &.back-by-5 {
    &.ng-enter,
    &.ng-enter-prepare {
      //       transform: translateY(-$height*5);
      top: -$height * 5;
    }
    &.ng-enter-active,
    &.ng-leave {
      //       transform: translateY(0px);
      top: 0px;
    }
    &.ng-leave-active {
      //       transform: translateY($height*5);
      top: $height * 5;
    }
  }
}

.cal-actions {
  .btn-view-wrapper {
    text-align: left;
    @media only screen and (min-width: 850px) {
      text-align: center;
    }
    .btn-view {
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.cal-month-box {
  margin-top: 15px;
  margin-bottom: 25px;
  height: 363px;
  //overflow: hidden;
  padding: 1px;
  position: relative;

  .cal-header {
    display: flex;
    flex-flow: row wrap;
    .cal-header-cell {
      width: $cellWidth;
      color: grey;
    }
  }
  .cal-wrapper {
    &--shadow {
      display: block;
      height: 100%;
      box-shadow: 0 6px 7px #e4e4e4;
    }
  }
  .cal-month-wrapper {
    display: flex;
    flex-flow: row wrap;
    //box-shadow: -1px 0 0 0 #cecece, 0 -1px 0 0 #cecece;
    //border-left: 1px solid #cecece;
    border-top: 1px solid #cecece;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    //animation
    &.ng-enter,
    &.ng-leave {
      transition: 1s ease all;
    }

    &.small-cells {
      @include calendarAnimation($smallCellHeight);
    }
    &.big-cells {
      @include calendarAnimation($cellHeight);
    }

    .cal-month-day {
      display: flex;
      flex-flow: row nowrap;
      height: $cellHeight;
      width: $cellWidth;
      border-right: solid 1px $light-grey;
      border-bottom: solid 1px $light-grey;
      transform: translate3d(0, 0, 0);
      will-change: transform;
      &.ng-animate {
        transition: all 1s !important;
        overflow: hidden;
      }
      &.cal-day-today {
        background-color: $light-blue;
        transition: all 0.5s ease-in;
        .cal-day-cell {
          .calendar_eventDay {
            background-color: $dark-blue;
            transition: all 0.5s ease-in;
          }
          .calendar_day-label {
            color: white;
            transition: all 0.5s ease-in;
          }
          .calendar_month-label {
            color: white;
            transition: all 0.5s ease-in;
          }
          .mask {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            pointer-events: none;
            /* to make clicks pass through */
            box-shadow: 0px 0px 0px 2px #fff inset, 0 0 0 3px $dark-blue inset;
          }
        }
      }
      .calendar_cruises-wrapper {
        padding: 4px;
        width: 80%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        @media only screen and (min-width: 992px) {
        }
        @media only screen and (min-width: 1200px) {
          width: 75%;
        }
        .calendar_bestPrice-wrapper {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          line-height: 120%;
        }
        .aDa {
          color: $dark-grey;
          font-size: 0.669em; //10px;
          text-transform: uppercase;
          margin-right: 4px;
          width: 50%;
        }
        .calendar_bestPrice {
          font-size: 1.231em; //16px
          font-weight: 600;
          color: $dark-blue;
        }

        &__departureNumber {
          opacity: 0;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 600;
          color: $base-grey;
          transition: opacity ease-out 0.15s;
          white-space: nowrap;
          font-size: 6px;
          @media only screen and (min-width: 992px) {
            font-size: 8px;
          }
        }
      }
      &.small-cell {
        height: $smallCellHeight;
        overflow: hidden;
        .calendar_cruises-wrapper {
          padding: 4px;
          width: 70%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
          @media only screen and (min-width: 992px) {
          }
          @media only screen and (min-width: 1200px) {
            width: 75%;
          }
          .circle-wrapper {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 2px;
            margin-top: 0px;
            &__dot {
              border-radius: 0;
              margin-left: 2px;
              border: 1px solid white;
              height: 8px;
              width: 8px;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .calendar_cruises-wrapper__departureNumber {
          display: none;
        }
      }
      &.in-range,
      &.start-range,
      &.end-range {
        position: relative;
      }
      &.in-range {
        border-bottom: solid 2px #94c7ed;
        box-shadow: 0px -2px 0px 0px #94c7ed;
        .calendar_eventDay {
          background-color: $base-blue;
          .calendar_day-label,
          .calendar_month-label {
            color: white;
          }
        }
      }
      &.in-range.start-range {
        box-shadow: -2px -2px 0px 0px #94c7ed, 0px -2px 0px 0px #94c7ed;
      }
      &.in-range.end-range {
        border-right: solid 2px #94c7ed;
      }
      &.cal-day-outmonth {
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &.weekday-start {
        clear: left;
      }
      &.has-events {
        .cal-day-cell {
          cursor: pointer;
          &:hover {
            .calendar_cruises-wrapper__departureNumber {
              opacity: 1;
              transition: opacity ease-in 0.15s;
            }
          }
        }
        &.active {
          background-color: $light-yellow;
          transition: all 0.5s ease-in;
          .cal-day-cell {
            .calendar_eventDay {
              background-color: $yellow;
              transition: all 0.5s ease-in;
            }
            .calendar_day-label {
              color: white;
              transition: all 0.5s ease-in;
            }
            .calendar_month-label {
              color: white;
              transition: all 0.5s ease-in;
            }
            .mask {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              pointer-events: none;
              /* to make clicks pass through */
              box-shadow: 0px 0px 0px 2px #fff inset, 0 0 0 3px $yellow inset;
            }
          }
        }
      }
      .cal-day-cell {
        display: flex;
        flex-flow: row nowrap;
        height: 100%;
        //height: 60px;
        width: 100%;
        // 		width: $cellWidth;
        position: relative;
        background: white;
      }
    }
    .calendar_eventDay {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      height: 93%;
      //height: 55px;
      color: $dark-grey;
      background-color: #f9f9fb;
      margin: 2px;
      width: 20%;
      font-size: 0.8em;
      line-height: 140%;
      @media only screen and (min-width: 992px) {
        width: 30%;
        font-size: 0.9em;
        line-height: 120%;
      }
      @media only screen and (min-width: 1200px) {
        width: 25%;
        font-size: 1em;
      }
      .calendar_day-label {
        color: $dark-grey;
        line-height: 90%;
        font-weight: 600;
        width: 20px;
        text-align: center;
        display: block;
      }
      .calendar_month-label {
        font-size: 0.769em; //10px;
        text-transform: uppercase;
        color: $gray-600;
        display: block;
      }
    }
  }
}
.circle-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2px;
  margin-top: 0px;
  &__dot {
    border-radius: 0;
    border: 1px solid white;
    display: inline-block;
    margin-left: 5px;
    height: 8px;
    width: 8px;
    &--mediterranean {
      background-color: $mediterraneo;
    }
    &--caribbean {
      background-color: $caraibi;
    }
    &--greateasterncruises {
      background-color: $transatlantiche;
    }
    &--transatlantic {
      background-color: $transatlantiche;
    }
    &--dubai {
      background-color: $dubai;
    }
    &--pacific_asia {
      background-color: $dubai;
    }
    &--north_europe {
      background-color: $nord-europa;
    }
    &--south_america {
      background-color: $caraibi;
    }
    &--persian_gulf {
      background-color: $dubai;
    }
    &--indian_ocean {
      background-color: $oceano-indiano;
    }
    &--round_world {
      background-color: $giro-del-mondo;
    }

    @media only screen and (min-width: 992px) {
      height: 12px;
      width: 12px;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.cal-legend-list {
  display: flex;
  flex-flow: column wrap;
  max-height: unset;
  @media only screen and (min-width: 768px) {
    max-height: 70px;
  }
  @media only screen and (min-width: 992px) {
    max-height: 70px;
  }
}
