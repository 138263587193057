.ladda-button[data-style=zoom-in][data-loading] {
  .ladda-label {
    opacity: 0;
    transform: none;
  }
}
.swal2-modal {
  font-family: Poppins, "Open Sans", sans-serif;
}
.swal2-container.swal2-shown{
  z-index:8888;
}
.isLoading {
  cursor: progress;
  > div {
    user-select: none;
    pointer-events: none;
  }
}
.loadingSpinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 7777;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($light-blue, .5);
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    /* l'immagine di sfondo è localizzata e inserita nel parziale loader.html */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 524px;
    width: 750px;
    border: 5px solid white;
    box-shadow: $box-shadow-lg;
    .fading-circle {
      width: 60px;
      height: 60px;
      position: relative;
      .circle {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        &:before {
          content: '';
          display: block;
          margin: -4px auto;
          width: 20%;
          height: 20%;
          background-color: #FFF;
          border-radius: 100%;
          animation: circleFadeDelay 1.2s infinite ease-in-out both;
          box-shadow: $box-shadow-lg;
        }
      }
      .circle2 {
        transform: rotate(30deg);
        &:before {
          animation-delay: -1.1s;
        }
      }
      .circle3 {
        transform: rotate(60deg);
        &:before {
          animation-delay: -1s;
        }
      }
      .circle4 {
        transform: rotate(90deg);
        &:before {
          animation-delay: -0.9s;
        }
      }
      .circle5 {
        transform: rotate(120deg);
        &:before {
          animation-delay: -0.8s;
        }
      }
      .circle6 {
        transform: rotate(150deg);
        &:before {
          animation-delay: -0.7s;
        }
      }
      .circle7 {
        transform: rotate(180deg);
        &:before {
          animation-delay: -0.6s;
        }
      }
      .circle8 {
        transform: rotate(210deg);
        &:before {
          animation-delay: -0.5s;
        }
      }
      .circle9 {
        transform: rotate(240deg);
        &:before {
          animation-delay: -0.4s;
        }
      }
      .circle10 {
        transform: rotate(270deg);
        &:before {
          animation-delay: -0.3s;
        }
      }
      .circle11 {
        transform: rotate(300deg);
        &:before {
          animation-delay: -0.2s;
        }
      }
      .circle12 {
        transform: rotate(330deg);
        &:before {
          animation-delay: -0.1s;
        }
      }
    }}
  &--modal {
    background-color: unset;
    .loader {
      border: none;
      box-shadow: none;
    }
  }
}

.mini-loader {
  color: $base-blue;
  display: inline-block;
  font-size: 2.5em;
  padding: 0 5px;
  vertical-align: middle;
}
.mini-loader:before {
  display: inline-block;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes spin {
  0%  {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}