/* stylelint-disable */
@import '../../node_modules/bootstrap/scss/_variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
/* stylelint-enable */

$custom-calendar-icon: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons / 16 / date-new%3C/title%3E%3Cg id='Icons-/-16-/-date-new' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='quanto' transform='translate(1.500000, 1.000000)' stroke='%23292929' stroke-width='1.157'%3E%3Cpath d='M5.80356881e-06,4.66476587 L13.0000058,4.66476587 M5.05874462,8.00000255 L7.94126119,8.00000255 M3.41711812,0 L3.41711812,1.98863606 M9.58288769,0 L9.58288769,1.98863606 M3.12627227,10.3333349 L9.87373354,10.3333349 M10.1368673,14 L2.86313845,14 C1.28613469,14 0,12.6808637 0,11.0634536 L0,3.85131384 C0,2.23390381 1.28616371,0.914776397 2.86313845,0.914776397 L10.1368673,0.914776397 C11.7138421,0.914776397 13,2.23390381 13,3.85131384 L13,11.0634536 C13,12.6808637 11.7138421,14 10.1368673,14 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

@mixin form-datepicker-style() {
  background-image: $custom-calendar-icon;
  background-size: 16px 14px;
}

.form-datepicker {
  @extend .form-select;
  @include form-datepicker-style();
  &--prepend {
    padding: $input-padding-y $form-select-padding-x $form-select-padding-y $form-select-padding-x * 3;
    background-position: left $form-select-padding-x center;
  }
}

.form-datepicker-sm {
  @extend .form-select-sm;
  @include form-datepicker-style();
}

.form-datepicker-lg {
  @extend .form-select-lg;
  @include form-datepicker-style();
}

input[type='date'] {
  &.form-datepicker {
    background-image: none;
    padding: $input-padding-y $input-padding-x;
  }
  &::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: $custom-calendar-icon no-repeat center / contain;
    width: 11px;
    height: 11px;
    border-width: thin;
  }
}
