.harbour-thumb {
  position: relative;
  height: 320px;
  background: no-repeat center;
  background-size: cover;
  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: " ";
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0,0.80) 100%);
  }
  &__title {
    color: white;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 1;
  }
}
.shorex-title-wrapper {
  padding: 20px 15px 0 15px;
}