$icomoon-font-family: 'CostaOTO' !default;
$icomoon-font-path: 'fonts' !default;

$icon-euro: '\e944';
$icon-privilegi-premium: '\e900';
$icon-privilegi-deluxe: '\e901';
$icon-bestprice: '\e902';
$icon-cabin-standard: '\e903';
$icon-cabin-suite: '\e904';
$icon-canc-leggera: '\e905';
$icon-costaclub: '\e906';
$icon-pacchetto-brindiamo: '\e907';
$icon-pacchetto-pranzocena: '\e908';
$icon-pacchetto-intenditore: '\e909';
$icon-trattameto-vip: '\e90a';
$icon-8lock: '\e90b';
$icon-prezzobloccato: '\e90c';
$icon-scontopremium: '\e90d';
$icon-neocollection: '\e90e';
$icon-molo: '\e90f';
$icon-priceguarantee: '\e910';
$icon-sor: '\e911';
$icon-3lock: '\e912';
$icon-cabina-balcone: '\e913';
$icon-cabina-suite: '\e914';
$icon-lock: '\e915';
$icon-disponibilita-limitata: '\e916';
$icon-canc-leggera-20: '\e917';
$icon-bevande-o-credito-cp: '\e918';
$icon-cabina-esterna: '\e919';
$icon-cabina-interna: '\e91a';
$icon-bevande-o-credito-cc: '\e91b';
$icon-telefono: '\e91c';
$icon-avviso: '\e91d';
$icon-binoculars: '\e91e';
$icon-cart: '\e91f';
$icon-hamburger: '\e920';
$icon-promos: '\e921';
$icon-regions: '\e922';
$icon-ship: '\e923';
$icon-all-you-can-sea: '\e924';
$icon-arrow-left: '\e925';
$icon-arrow-right: '\e926';
$icon-pdf: '\e927';
$icon-think-before-print: '\e928';
$icon-home: '\e929';
$icon-chevron-left: '\e92a';
$icon-chevron-right: '\e92b';
$icon-chevron-down: '\e92c';
$icon-chevron-up: '\e92d';
$icon-filter: '\e92e';
$icon-babycot: '\e92f';
$icon-bath: '\e930';
$icon-wheelchair: '\e931';
$icon-matrimoniale: '\e932';
$icon-queen: '\e933';
$icon-rollbed: '\e934';
$icon-singolo: '\e935';
$icon-shower: '\e936';
$icon-sofa: '\e937';
$icon-honeymoon: '\e938';
$icon-help: '\e939';
$icon-view-card: '\e93a';
$icon-view-list: '\e93b';
$icon-plane-train: '\e93c';
$icon-icon-plane-bus: '\e945';
$icon-cookie: '\e93d';
$icon-in-partenza: '\e93e';
$icon-jump: '\e93f';
$icon-mese-di-vendita: '\e940';
$icon-passeggeri: '\e941';
$icon-target: '\e942';
$icon-to-do: '\e943';
