.modal-csv-viewer {
  > .modal-dialog {
    &.modal-lg {
      @media (min-width: 768px) {
        width: 992px;
      }
      @media (min-width: 992px) {
        width: 1200px;
      }
    }
  }
}

.modal-shipinfo {
  .container {
    width: auto;
    margin-bottom: 0;
  }
  .modal-header,
  .modal-body {
    padding: 0;
  }
  .modal-body {
    padding-bottom: 0;
  }
  .close {
    padding: 6px 15px;
  }
  .owl-carousel--cabins__element {
    padding-bottom: 15px;
    @media screen and (min-width: 0) {
      //height: 420px;
      width: 640px;
    }
    @media screen and (min-width: 992px) {
      //height: 420px;
    }
    @media screen and (min-width: 1170px) {
      //height: 420px;
      width: 765px;
    }
    .card-hero--cabin {
      //height: 100%;
    }
  }
  .hgroup__title {
    font-size: 1.6em;
  }
  .nav-tabs-blue {
    box-shadow: none;
  }
  .jumbo-picture {
    height: 360px;
  }
  .card {
    &:hover {
      transform: none;
    }
  }
  .tab-pane {
    padding-bottom: 25px;
  }
  .pic--squared:hover {
    filter: brightness(0.8);
  }
  .pic--squared:hover .magnify-photo {
    display: block;
    transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  }
  .pic--squared {
    height: 230px;
    width: 230px;
  }
  .pic--squared .magnify-photo {
    display: none;
    position: absolute;
    color: white;
    background: #a2e2ff45;
    padding: 10px;
    border-radius: 3px;
    font-weight: 700;
    bottom: 4px;
    right: 4px;
    outline: 2px #adadad solid;
    font-size: 12px;
  }
}

.close {
  font-size: 28px;
}

.modal-article {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 0) {
  .cck-page .modal-brochure-gruppi .modal-dialog.modal-lg {
    min-width: 750px;
    width: 750px;
  }
}
@media (min-width: 992px) {
  .cck-page .modal-brochure-gruppi .modal-dialog.modal-lg {
    width: 900px;
  }
}
@media (min-width: 0) {
  .cck-page .modal-brochure-gruppi .modal-dialog.modal-lg {
    width: 768px;
  }
}
@media (min-width: 992px) {
  .cck-page .modal-brochure-gruppi .modal-dialog.modal-lg {
    width: 992px;
  }
}
@media (min-width: 1200px) {
  .cck-page .modal-brochure-gruppi .modal-dialog.modal-lg {
    width: 1200px;
  }
}

.modal-ldm {
  font-size: 13px;
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .panel-body {
    display: flex;
    flex-flow: column nowrap;
    min-height: 360px;
    .cta-wrapper {
      margin-top: auto;
      margin-bottom: 0;
    }
  }
  .panel-footer {
    .btn-costa {
      margin: 15px 0;
    }
  }
  .panel-group {
    margin-bottom: 0;
  }
  .cta-wrapper {
    .btn {
      flex-basis: 175px;
      min-width: 175px;
    }
  }
  .label-help {
    font-size: 11px;
    color: #999999;
    text-align: right;
  }
  p {
    color: #4a4a4a;
    line-height: 26px;
  }
  .panel-default {
    .panel-heading {
      background: #ddd;
      .panel-title {
        font-size: 1.154em;
        font-weight: 600;
      }
    }
  }
  .col-xs-6 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.circled-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  height: 26px;
  width: 26px;
  background-color: #fff;
  color: $base-blue;
  &--modify {
    color: $dark-blue;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 66s ease-out;
    &:hover {
      opacity: 1;
      transition: opacity 66s ease-in;
    }
  }
}

.modal-content {
  .cruise-fares-table {
    width: auto;
    .fares-table {
      .cruise-fares-wrapper {
        .cruise-fares-row {
          .fares-container {
            height: 40px;
          }
          &.double-row {
            .fares-container {
              height: 80px;
            }
          }
          &.cruise-fares-table-header {
            .fares-cell {
              font-size: 13px;
            }
          }
          .fares-cell {
            height: 40px;
            .price,
            .aDa {
              font-size: 13px;
            }
            .icon-indicator {
              right: 15px;
            }
          }
        }
        .fares-table__footerMessage {
          font-size: 13px;
        }
      }
      .fares-wrapper--size-md {
        height: 25px;
      }
    }
  }
}

@import 'partials/itinerary-modal';
@import 'partials/print-quote-modal';
