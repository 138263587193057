.cck-main.cck-generic .complex-table-wrapper .flex-vcenter {
  display: flex;
  align-items: center;
  &-hcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.cck-main.cck-generic .complex-table-wrapper .flex-hcenter {
  display: flex;
  justify-content: center;
}

.booking-report-legenda {
  display: flex;
  justify-content: flex-end;
  div {
    height: 35px;
    text-align: right;
    padding: 0 10px;
    display: flex;
    background: #fff;
    justify-content: flex-end;
    align-items: center;
    margin: 8px;
    text-transform: uppercase;
    font-size: 86%;
    font-weight: 600;
  }
  :first-child {
    border-right: 5px solid #cfffcf;
  }
  :nth-child(2) {
    border-right: 5px solid #ffcfcf;
  }
}

.cck-main.cck-generic .complex-table-wrapper .deposit-cell {
  display: flex;
  padding: 0 20px;
  flex-direction: column;

  > .deposit-cell__inner_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    > small {
      text-align: left;
    }
    > b {
      text-align: right;
      width: 100%;
    }
  }
}

.cck-main.cck-generic .complex-table-wrapper .amount-cell {
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  &__no-due {
    border-right: 5px solid #cfffcf;
  }
  &__to-due {
    border-right: 5px solid #ffcfcf;
  }

  > .amount-cell__inner_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    > small {
      text-align: left;
      max-width: 50%;
    }
    > b {
      text-align: right;
      width: 100%;
    }
  }
}
