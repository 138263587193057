.logo {
  width: 100%;
  height: 45px;
  display: block;
  position: relative;
  &--mycosta {
    background: url('/Style%20Library/CostaClickNew/images/logos/mycosta-logo.png') center no-repeat / contain;
  }
  &__costa {
    background: url('/Style%20Library/CostaClickNew/images/logos/costa_logo_neg2021.png') center no-repeat / contain;
    height: 38px;
    display: block;
  }
}
.passepartour-logo {
  height: 20px;
  width: 100%;
  margin-bottom: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  @media only screen and (max-width: 992px) {
    height: 36px;
    margin-bottom: -13px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: 40%;
    background-position: 26px 22px !important;
  }
  &--small {
    height: 15px;
    width: 65px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &--big {
    height: 30px;
    width: 130px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
