.data-paragraph {
  font-family: Poppins, "Open Sans", sans-serif;
  margin: 0 0 5px;
  cursor: default;
  &__header {

  }

  &__data {
    margin-left: 3px;
  }

  &__intro {
    font-weight: 600;
    display: block;
    color: $gray-600;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.85em;
  }

  &__icon {
    color: $dark-blue;
  }

  &__title {
    font-family: Poppins, sans-serif;
    color: $dark-blue;
    font-size: 1.5em;
    line-height: 1.2;
    margin-bottom: 10px;
    display: block;
  }

}