@import 'src/styles/variables';

.chart-header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-items: flex-start;
  margin: 0 15px;
  gap: 0.5rem;
  &--separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }
  &--separator-blue {
    border-bottom: 1px solid rgba(16, 114, 185, 0.5);
  }
  &--text-white {
    color: #ffffff;
  }
  &--text-secondary {
    color: $black;
  }
  &--text-dark-blue {
    color: #063c5d;
  }
  &__icon {
    font-size: 30px;
    line-height: 30px;
    flex: 0 0 8%;
    width: 8%;
  }
  &__side-infos {
    flex: 0 0 35%;
    width: 35%;
  }
  &--adduro-fix {
    margin-top: -10px;
  }
}

.chart-title {
  flex: 1 0 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  &__h1 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__h2 {
    font-weight: 600;
    font-size: 16px;
    color: #4a4a4a;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__h5 {
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__p {
    font-size: 13px;
  }
  &__small {
    font-size: 13px;
    color: $gray-700;
    line-height: 21px;
  }
  &--white {
    color: #fff;
  }
  &--grey {
    color: #4a4a4a;
  }
  &--light-grey {
    color: #a8a8a8;
  }
  &--blue {
    color: #0085c7;
  }
}

.chart-data {
  display: block;
  &--text-white {
    color: #fff;
  }
  &__table {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    &:before {
      content: '';
      flex: 0 0 calc(5% - 8px);
    }
  }
  &__separator {
    border-bottom: 1px solid #dddddd;
  }
}

.chart-data-small {
  .table > thead > tr > th {
    border-bottom: 1px solid rgba(255, 2555, 255, 0.7);
  }
  .chart-data__table {
    &:after {
      content: '';
      flex: 0 0 calc(5% - 8px);
    }
  }
}

.chart-banner {
  margin: 38px 35px 15px;
}

.chart-data-header {
  display: flex;
  flex-flow: row wrap;
  margin-top: 15px;
  margin-bottom: 6px;
  &--margin {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  &__icon {
    flex: 0 0 5%;
  }
  &__title {
    flex: 1 0 0;
  }
  &__declaration {
    flex: 0 0 25%;
    text-align: right;
  }
}

.chart-data-profit {
  &__declaration {
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    padding-right: 6px;
    &--blue {
      color: #0071a3;
    }
    &--red {
      color: #fe3973;
    }
  }
}

.chart-data-table {
  background-color: transparent;
  margin-bottom: 10px;
  padding: 0 15px;
  &--center {
    th,
    td {
      text-align: center;
      padding: 4px;
      font-size: 16px;
      line-height: 19px;
    }
    td {
      border-top: 1px solid rgba(255, 255, 255, 0.7);
    }
  }
  &__label {
    padding: 4px 8px 4px !important;
  }
  &__declaration {
    padding: 4px 8px 4px !important;
    text-align: right;
    font-weight: 600;
  }
}

.chart-indicator {
  height: 18px;
  width: 18px;
  content: '';
  // margin-top: 2px;
  margin-right: 3px;
  &--small {
    height: 13px;
    width: 13px;
  }
  &--micro {
    height: 8px;
    width: 8px;
  }
  &--circle {
    border-radius: 100%;
  }
  &--blue {
    background-image: linear-gradient(
      -45deg,
      #1270e3 0%,
      #1c74e5 6%,
      #2379e7 12%,
      #2a7ee9 19%,
      #3083eb 26%,
      #3688ed 34%,
      #3b8def 42%,
      #4092f1 50%,
      #4597f4 58%,
      #499cf6 66%,
      #4da0f8 74%,
      #50a4fa 81%,
      #54a8fc 88%,
      #57abfd 94%,
      #59aeff 100%
    );
  }
  &--red {
    background-image: linear-gradient(
      -177deg,
      #fc087e 0%,
      #fc1a7d 6%,
      #fc257c 12%,
      #fd2e7a 19%,
      #fd3679 26%,
      #fd3e77 34%,
      #fd4476 42%,
      #fe4a74 50%,
      #fe5072 58%,
      #fe5571 66%,
      #fe5a6f 74%,
      #fe5f6d 81%,
      #ff626c 88%,
      #ff666a 94%,
      #ff6969 100%
    );
  }
  &--orange {
    background-image: linear-gradient(
      135deg,
      #fce38a 0%,
      #fce08a 5%,
      #fbdc89 9%,
      #fbd789 12%,
      #fad088 15%,
      #f9c887 19%,
      #f8bf86 22%,
      #f7b585 27%,
      #f6ab84 32%,
      #f5a183 38%,
      #f59783 46%,
      #f48f82 56%,
      #f38881 68%,
      #f38381 83%,
      #f38181 100%
    );
  }
}

.commission-earned {
  &__label {
    font-size: 13px;
    text-align: right;
  }
  &__money {
    font-size: 20px;
    text-align: right;
  }
  &__value {
    font-size: 16px;
  }
}

.chart-wrapper {
  position: relative;
  height: 225px;
  color: #fff;
  &__label {
    text-transform: uppercase;
    opacity: 0.76;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
    padding-left: 20px;
    display: block;
  }
}

.chartjs-tooltip {
  text-align: center;
  background-color: #fff;
  padding: 12px 10px 10px !important;
  border-radius: 0;
  z-index: 1050;
  transform: translate(-50%, -120%);
  transition: opacity 0.2s ease;
  position: relative;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.15);
  &:before {
    position: absolute;
    content: '';
    bottom: -7px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
  }
  &__title {
    color: #4a4a4a;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
  &__body {
    color: #0071a3;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 10px;
  }
}

.chart-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
  &--background-blue {
    background: #0071a3;
  }
  &--background-azure {
    background: #b4d5ea;
  }
  &--background-yellow {
    background: #fed230;
  }
}

.chart-filter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 15px;
  border-radius: 0;
  &__title {
    font-weight: 600;
    margin-right: 10px;
  }
  &__checkbox {
    margin-right: 25px;
  }
}

.indicator-icon {
  display: inline-block;
  content: '';
  height: 11px;
  width: 11px;
  margin-right: 3px;
  &--rounded {
    border-radius: 0;
  }
  &--blue {
    background: #0071a3;
  }
  &--purple {
    background: #9013fe;
  }
  &--yellow {
    background: #fed230;
  }
  &--coral {
    background: #f38281;
  }
  &--green {
    background: #01c389;
  }
  &--lightgreen {
    background: #dbf1d9;
  }
  &--green1 {
    background: $color-green1;
  }
  &--green2 {
    background: $color-green2;
  }
  &--green3 {
    background: $color-green3;
  }
  &--green4 {
    background: $color-green4;
  }
  &--green5 {
    background: $color-green5;
  }
  &--green6 {
    background: $color-green6;
  }
}

.indicator {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  width: 90%;
  &__icon {
    flex: 0 0 10%;
  }
  &__year {
    flex: 0 0 30%;
  }
  &__declaration {
    flex: 0 0 60%;
    text-align: right;
  }
}
