// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../node_modules/bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'styles/variables';

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';

// 4. Include any default map overrides here
@import 'styles/utilities';

// 5. Include remainder of required parts
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '../node_modules/bootstrap/scss/bootstrap';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../node_modules/bootstrap/scss/utilities/api';

// 8. Add additional custom code here
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import 'styles/custom';