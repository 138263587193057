.summary-body {
  padding: 10px 8.8% 10px;
  &__header {
    margin-bottom: 0px;
  }
  &__content {
    width: 100%;
  }
  &__main-info {
    display: flex;
    flex-flow: row;
    margin-bottom: 5px;
  }
  &__map {
    height: 140px;
    width: 140px;
    border: 1px solid #D0E3F1;
    border-radius: 0;
    margin-right: 15px;
  }
  &__table {
    margin-bottom: -15px;
  }
}