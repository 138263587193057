.sorting-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid $light-grey;
  background: #fff;

  margin-bottom: 15px;
  padding: 0 15px;
  height: 38px;
  font-size: 0.85em;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: $medium-grey;
  box-shadow: $box-shadow;

  .sorting-cell {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    border-right: 1px solid white;
    cursor: pointer;
    &:last-child {
      border-right: 0;
    }
    &.active {
      color: $base-blue;
      font-weight: 600;
    }
  }
  .chevron-order-wrapper {
    height: 16px;
    width: 16px;
    margin-left: 4px;
  }
  .date {
    width: 75px;
    flex: 0 0 75px;
    margin-left: 5px;
    @media only screen and (min-width: 1200px) {
      margin-left: 16px;
    }
  }
  .days {
    width: 80px;
    justify-content: center;
  }
  .itinerary {
    width: 285px;
    @media only screen and (min-width: 992px) {
      width: 300px;
    }
    @media only screen and (min-width: 1200px) {
      width: 400px;
    }
  }
  .ship {
    width: 90px;
    margin-right: 130px;
    @media only screen and (min-width: 1200px) {
      width: 70px;
      margin-right: 190px;
    }
  }
  .flight {
    width: 38px;
    @media only screen and (min-width: 1200px) {
      width: 45px;
    }
    i {
      font-size: 1.45em;
    }
  }
  .fare {
    width: 30px;
    @media only screen and (min-width: 1200px) {
      width: 50px;
    }
  }
  .price {
    justify-content: flex-end;
    margin-left: auto;
    width: 70px;
    @media only screen and (min-width: 996px) {
      width: 85px;
    }
  }
}
