.jumbo-intro {
  padding-top: 30px;
  padding-bottom: 80px;
  h1 {
    font-style: normal;
  }
  &--less-padding {
    padding-top: 15px;
    padding-bottom: 10px;
  }
  &--inverted-padding {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

.jumbo-picture {
  position: relative;
  background: no-repeat center;
  background-size: cover;
  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
  }
  @media (min-width: 0) {
    height: 420px;
  }
  @media (min-width: 992px) {
    height: 480px;
  }
  @media (min-width: 1200px) {
    height: 520px;
  }
}
