.table-status-indicator {
  display: inline-flex;
  flex-flow: row;
  align-items: center;

  &__label {
    font-weight: 600;
    text-transform: uppercase;
    color: $gray-600;
  }

  &__icon {
    display: inline-block;
    font-family: 'bootstrap-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    margin-right: 3px;

    &--red {
      &:before {
        content: '\F622';
        color: $red;
      }
    }

    &--green {
      &:before {
        content: '\F26A';
        color: $green;
      }
    }

    &--yellow {
      &:before {
        content: '\F287';
        color: $yellow;
      }
    }
  }
}
