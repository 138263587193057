$fareCellHeight: 30px;
.cruise-fares-table {
  display: block;
  order: 1;
  width: 100%;
  @media (min-width: 1200px) {
    order: 0;
    width: 70%;
  }
  .fares-table {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    .cruise-fares-body {
      @for $i from 1 through 7 {
        &.rows-#{$i} {
          min-height: $fareCellHeight * $i;
          margin-bottom: 35px;
        }
      }
    }
    div {
      &.ng-animate.ng-leave {
        display: none;
      }
      &.ng-leave,
      &.ng-enter.ng-enter-active {
        transition: 0.5s ease all;
        opacity: 1;
        background: #fff;
      }
      &.ng-leave.ng-leave-active,
      &.ng-enter {
        transition: 0.5s ease all;
        opacity: 0;
        background: lighten($light-yellow, 10%);
      }
    }
    .cruise-fares-row {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      &.cruise-fares-table-header {
        .fares-container,
        .fares-cell {
          height: 15px;
          border-right: 0;
          font-size: 0.8em;
          color: $base-grey;
        }
      }

      &.double-row {
        .fares-container {
          position: absolute;
          height: 60px;
          small.fares-total {
            position: absolute;
            right: 4px;
            top: 6px;
            font-size: 0.5em;
            letter-spacing: 0.7px;
          }
        }
        .fares-wrapper {
          z-index: 1;
        }
        .fares-cell:nth-child(2) {
          margin-left: 20%;
        }
      }
      .fares-container {
        position: relative;
        display: flex;
        align-items: center;
        height: $fareCellHeight;
        border-right: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        width: 20%;
        cursor: pointer;
        small.fares-total {
          position: absolute;
          right: 4px;
          top: 14px;
          font-size: 0.5em;
          letter-spacing: 0.7px;
        }
        .fares-image {
          width: 20px;
          @media only screen and (min-width: 992px) {
            width: 100%;
          }
        }
      }
      .fares-cell {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        height: $fareCellHeight;
        border-right: 1px solid $light-grey;
        border-bottom: 1px solid #cecece;
        line-height: 80%;
        transition: background ease-out 0.5s;
        position: relative;
        width: 20%;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
        &.cursor-pointer {
          &:hover {
            text-decoration: underline;
          }
        }
        &.active {
          background: $light-yellow;
          box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 3px $medium-yellow;
          transition: background ease-in 0.5s;
          .price {
            color: $dark-blue;
          }
        }
        .aDa {
          font-size: 0.846em; //11px
          font-weight: 600;
          color: $dark-grey;
          margin: 0 2px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .price {
          font-size: 1em; //13px
          color: $base-blue;
        }
        &__dispLimitata {
          font-size: 1.231em; //16px
          position: relative;
          margin-left: 4px;
          @media only screen and (min-width: 992px) {
            position: absolute;
            right: 4px;
            margin-left: 0;
          }
        }
        &__blocked-by-networkfare {
          pointer-events: none;
          .aDa {
            color: $medium-grey;
          }
          .price {
            color: $medium-grey;
          }
        }
      }

      &__blocked-by-networkfare {
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1);
        opacity: 0.6;
      }
    }
  }
  .cruise-fares-footer {
    margin-top: auto;
    min-height: 36px;
    padding-top: 5px;
    > .row-flex {
      flex-flow: column-reverse nowrap;
      align-items: flex-start;
      @media only screen and (min-width: 992px) {
        flex-flow: row nowrap;
        align-items: flex-end;
      }
    }
    .promo-switch-title {
      white-space: nowrap;
      &.disabled {
        opacity: 0.5;
      }
    }
    .ats-switch {
      border-radius: 0;
      border: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 0.9em;
      height: 27px;
      min-width: 60px;
      margin-bottom: 10px;
      @media only screen and (min-width: 992px) {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: none;
      }
      &:focus {
        outline: none;
      }
      .switch-on {
        .knob {
          border: 1px solid $green;
          transition: all ease-in 0.66s;
        }
      }
      .knob {
        border: 1px solid #ccc;
        margin-top: 0;
        margin-bottom: 0;
        transition: all ease-out 0.66s;
        height: 23px;
      }
      .switch-left,
      .switch-right,
      .knob {
        min-height: 15px;
        height: 23px;
        line-height: 130%;
        color: #fff;
        width: 33%;
      }
      span {
        font-weight: 800;
        text-transform: uppercase;
        &.switch-right {
          color: #fff;
          background: #ccc;
          font-weight: 600;
        }
        &.switch-left {
          background: $green;
          color: $dark-blue;
        }
      }
    }
  }
  &--new {
    .fares-table {
      .cruise-fares-wrapper {
        border: 1px solid #f1f1f1;
        border-radius: 0;
        padding: 10px 5px 5px;
        .cruise-fares-body {
          @for $i from 1 through 7 {
            &.rows-#{$i} {
              min-height: $fareCellHeight * $i - 10px;
              margin-bottom: unset;
            }
          }
        }
        .fares-container {
          border-right: 1px solid #f1f1f1;
          border-bottom: 1px solid #f1f1f1;
        }
        &--4-columns {
          .cruise-fares-row {
            .fares-container,
            .fares-cell {
              width: 20%;
            }
          }
        }
        &--5-columns {
          .cruise-fares-row {
            .fares-container,
            .fares-cell {
              width: 16.666666%;
            }
          }
        }
        .cruise-fares-row {
          &.cruise-fares-table-header {
            .fares-cell {
              color: #063c5d;
              border-right: none;
              border-bottom: 1px solid #f1f1f1;
              font-weight: 600;
              letter-spacing: 0.4px;
              text-align: center;
              font-size: 9px;
              @media (min-width: 992px) {
                font-size: 9px;
              }
              @media (min-width: 1200px) {
                font-size: 10px;
              }
            }
          }
          .fares-cell {
            border-bottom: 1px solid #f1f1f1;
            &:last-child {
              border-right: none;
            }
            .aDa,
            .price {
              font-weight: 600;
              font-size: 10px;
              color: #063c5d;
              letter-spacing: 0.4px;
              text-align: center;
            }
          }
        }
      }
      .fares-wrapper {
        &:before {
          content: unset;
        }
        &--size-md {
          height: 22px;
          width: 100%;
          flex: 0 0 100%;
        }
        &__image {
          background: center no-repeat;
          content: '';
          display: block;
        }
      }
      &__footerMessage {
        font-size: 10px;
        color: #4a4a4a;
        letter-spacing: 0.4px;
        margin: 5px 0 0;
      }
    }
  }
}

.icon-indicator {
  position: absolute;
  right: 6px;
  font-size: 13px;
  border: 1px solid #fff;
  border-radius: 13px;
  // height: 13px;
  // width: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  &--red {
    color: $red;
  }
  &--green {
    color: $green;
  }
  &--yellow {
    color: $yellow;
  }
  &--orange {
    color: $orange;
  }
}

// .icon-indicator-new {
//   position: absolute;
//   display: flex;
//   align-items: center;
//   bottom: 20%;
//   justify-content: center;
//   width: 14px;
//   border: 0;
//   border-radius: 0;
//   opacity: 0.55;

//   &--red {
//     border-bottom: 3px solid $red;
//   }
//   &--green {
//     border-bottom: 3px solid $green;
//   }
//   &--yellow {
//     border-bottom: 3px solid $yellow;
//   }
//   &--orange {
//     border-bottom: 3px solid $orange;
//   }
// }

// .icon-indicatorsss-new {
//   position: absolute;
//   display: flex;
//   align-items: center;
//   top: 3px;
//   right: 3px;
//   justify-content: center;
//   border-radius: 0;
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 0 10px 10px 0;
//   &--red {
//     border-color: transparent $red transparent transparent;
//   }
//   &--green {
//     border-color: transparent $green transparent transparent;
//   }
//   &--yellow {
//     border-color: transparent $yellow transparent transparent;
//   }
//   &--orange {
//     border-color: transparent $orange transparent transparent;
//   }
// }
