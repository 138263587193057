.nav-tab-container {
  .tab-pane {
    padding: $card-spacer-y $card-spacer-x;
  }
}

.nav-tabs-blue {
  background-color: $base-blue;
  color: #fff;
  box-shadow: $box-shadow;
  .nav-tabs {
    padding-top: 3px;
    > li {
      &.active {
        > a {
          color: $medium-blue;
          cursor: default;
          background-color: #fff;
          border: 1px solid transparent;
          border-bottom-color: transparent;

          &:focus,
          &:hover {
            color: $medium-blue;
            cursor: default;
            background-color: #fff;
            border: 1px solid transparent;
            border-bottom-color: transparent;
          }
        }
      }
      > a {
        color: white;
        border-radius: 0;
        &:hover,
        &:focus {
          background-color: darken($base-blue, 20%);
          border: 1px solid transparent;
        }
      }
    }
  }
  .tab-content {
    background-color: #fff;
  }
}

.nav-tabs-white {
  background-color: #fff;
  color: $medium-blue;
  box-shadow: $box-shadow;
  &.nav-cabins,
  &.nav-guest {
    border: 1px solid $border-color;
    border-bottom: 0;
    .nav-tabs {
      border-bottom: 0;
    }
    li {
      max-width: 15%;
      white-space: nowrap;
      overflow: hidden;
      &:last-of-type {
        margin-right: 5px;
      }
    }
  }
  .nav-tabs {
    > li {
      > a {
        color: $medium-blue;
        font-weight: 600;
        border-radius: 0;
        border: 2px solid transparent;
        border-bottom: 0;

        &:hover,
        &:focus {
          background-color: darken(#fcfcfc, 5%);
        }
      }

      &.active {
        a {
          color: #fff;
          cursor: default;
          background-color: $base-blue;
          border: 2px solid transparent;
          box-shadow: inset 0 3px 0 #fff;
          border-bottom-color: transparent;

          &:focus,
          &:hover {
            color: #fff;
            cursor: default;
            background-color: $base-blue;
            border: 2px solid transparent;
            box-shadow: inset 0 3px 0 #fff;
            border-bottom-color: transparent;
          }
        }
      }

      &.is-invalid,
      &.has-error {
        a {
          box-shadow: inset 0 3px 0 $danger;
        }
      }
    }
  }
}

.nav-tabs-lg {
  li {
    > a {
      font-weight: 600;
      font-size: 1em; //13px
      //padding: 12px 15px;
      @media only screen and (min-width: 992px) {
        font-size: 1.2em; //15px
        //padding: 13px;
      }
    }
  }
}
