.group-cabins-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  &__value {
    flex: 1 0 50%;
  }

  &__controls {
    flex: 1 0 50%;
    font-size: 18px;
  }

  &__icon {
    color: $dark-blue;

    &--remove {

    }
  }
}