/* RESULT */

.order-wrapper {
  background-color: #fff;
  padding: 4px 15px;
  margin: 15px 0;
  border: 1px solid $light-grey;
  box-shadow: $box-shadow;
  user-select: none;
  .row-flex {
    /*height: 38px;*/
    align-items: center;
  }
  span.views {
    padding-right: 15px;
  }
  h5.h5 {
    line-height: 1.3;
  }
}

@import 'partials/cruise-fares-table';
@import 'partials/cruise-sorting-bar';

/* RESULT END */
