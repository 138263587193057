@import "variables/costaOTO.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?133546');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?133546#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?133546') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?133546') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?133546##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-euro {
  &:before {
    content: $icon-euro;
  }
}

.icon-privilegi-premium {
  &:before {
    content: $icon-privilegi-premium;
  }
}
.icon-privilegi-deluxe {
  &:before {
    content: $icon-privilegi-deluxe;
  }
}
.icon-bestprice {
  &:before {
    content: $icon-bestprice;
  }
}
.icon-cabin-standard {
  &:before {
    content: $icon-cabin-standard;
  }
}
.icon-cabin-suite {
  &:before {
    content: $icon-cabin-suite;
  }
}
.icon-canc-leggera {
  &:before {
    content: $icon-canc-leggera;
  }
}
.icon-costaclub {
  &:before {
    content: $icon-costaclub;
  }
}
.icon-pacchetto-brindiamo {
  &:before {
    content: $icon-pacchetto-brindiamo;
  }
}
.icon-pacchetto-pranzocena {
  &:before {
    content: $icon-pacchetto-pranzocena;
  }
}
.icon-pacchetto-intenditore {
  &:before {
    content: $icon-pacchetto-intenditore;
  }
}
.icon-trattameto-vip {
  &:before {
    content: $icon-trattameto-vip;
  }
}
.icon-8lock {
  &:before {
    content: $icon-8lock;
  }
}
.icon-prezzobloccato {
  &:before {
    content: $icon-prezzobloccato;
  }
}
.icon-scontopremium {
  &:before {
    content: $icon-scontopremium;
  }
}
.icon-neocollection {
  &:before {
    content: $icon-neocollection;
  }
}
.icon-molo {
  &:before {
    content: $icon-molo;
  }
}
.icon-priceguarantee {
  &:before {
    content: $icon-priceguarantee;
  }
}
.icon-sor {
  &:before {
    content: $icon-sor;
  }
}
.icon-3lock {
  &:before {
    content: $icon-3lock;
  }
}
.icon-cabina-balcone {
  &:before {
    content: $icon-cabina-balcone;
  }
}
.icon-cabina-suite {
  &:before {
    content: $icon-cabina-suite;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-disponibilita-limitata {
  &:before {
    content: $icon-disponibilita-limitata;
  }
}
.icon-canc-leggera-20 {
  &:before {
    content: $icon-canc-leggera-20;
  }
}
.icon-bevande-o-credito-cp {
  &:before {
    content: $icon-bevande-o-credito-cp;
  }
}
.icon-cabina-esterna {
  &:before {
    content: $icon-cabina-esterna;
  }
}
.icon-cabina-interna {
  &:before {
    content: $icon-cabina-interna;
  }
}
.icon-bevande-o-credito-cc {
  &:before {
    content: $icon-bevande-o-credito-cc;
  }
}
.icon-telefono {
  &:before {
    content: $icon-telefono;
  }
}
.icon-avviso {
  &:before {
    content: $icon-avviso;
  }
}
.icon-binoculars {
  &:before {
    content: $icon-binoculars;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger;
  }
}
.icon-promos {
  &:before {
    content: $icon-promos;
  }
}
.icon-regions {
  &:before {
    content: $icon-regions;
  }
}
.icon-ship {
  &:before {
    content: $icon-ship;
  }
}
.icon-all-you-can-sea {
  &:before {
    content: $icon-all-you-can-sea;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon-think-before-print {
  &:before {
    content: $icon-think-before-print;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-babycot {
  &:before {
    content: $icon-babycot;
  }
}
.icon-bath {
  &:before {
    content: $icon-bath;
  }
}
.icon-wheelchair {
  &:before {
    content: $icon-wheelchair;
  }
}
.icon-matrimoniale {
  &:before {
    content: $icon-matrimoniale;
  }
}
.icon-queen {
  &:before {
    content: $icon-queen;
  }
}
.icon-rollbed {
  &:before {
    content: $icon-rollbed;
  }
}
.icon-singolo {
  &:before {
    content: $icon-singolo;
  }
}
.icon-shower {
  &:before {
    content: $icon-shower;
  }
}
.icon-sofa {
  &:before {
    content: $icon-sofa;
  }
}
.icon-honeymoon {
  &:before {
    content: $icon-honeymoon;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-view-card {
  &:before {
    content: $icon-view-card;
  }
}
.icon-view-list {
  &:before {
    content: $icon-view-list;
  }
}
.icon-plane-train {
  &:before {
    content: $icon-plane-train;
  }
}
.icon-icon-plane-bus {
  &:before {
    content: $icon-icon-plane-bus;
  }
}
.icon-cookie {
  &:before {
    content: $icon-cookie;
  }
}
.icon-in-partenza {
  &:before {
    content: $icon-in-partenza;
  }
}
.icon-jump {
  &:before {
    content: $icon-jump;
  }
}
.icon-mese-di-vendita {
  &:before {
    content: $icon-mese-di-vendita;
  }
}
.icon-passeggeri {
  &:before {
    content: $icon-passeggeri;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-to-do {
  &:before {
    content: $icon-to-do;
  }
}

