.cruiseInfo_deckPlans {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 220px;
}
.cruiseInfo_selectedDeck {
  margin: 50px 0;
  border: 0;
}
.cruiseInfo_cabineLegendRow {
  font-size: 13px;
  line-height: 120%;
  margin-bottom: 10px;
  .cruiseInfo_cabinLegend {
    height: 16px;
    width: 30px;
    margin-top: 2px
  }
}
.immagini_ponti {
  font-size: 13px;
  line-height: 120%;
  li {
    margin-bottom: 2px;
  }
}
.icon-deck-legend {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 14px;
  width: 20px;
  display: inline-block;
  vertical-align: middle
}
.nota-bene {
  font-size: 13px;
  line-height: 120%;
}