.group-type-list {
  display: flex;
  flex-flow: row;
  margin: 0 -15px 25px;

  &__cell {
    flex: 1 0 25%;
    max-width: 25%;
  }
  &__element {
    background-color: $blue;
    color: $white;
    margin: 0 15px;
    padding: 0 10px 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    border-radius: 0;
    transform: translate3d(0, 0, 0);
    box-shadow: $box-shadow;
    transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:active {
      color: #FFF;
    }
    &:hover {
      text-decoration: none;
      transform: translate3d(0, -3px, 0);
      box-shadow: $box-shadow-lg;
      transition: box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
  &__title {
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
  }
  &__description {
    font-family: Poppins, sans-serif;
    text-align: center;
  }
}
.group-status-caption-wrapper {
  margin:20px;
}