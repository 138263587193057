.gift-card-wrapper {
  background-color: #fff;
  color: $dark-blue;
  border: 1px solid $blue;
  border-radius: 0;
  box-shadow: none;
  &--panel {
    min-height: 160px;
  }
}

.gift-card-row {
  display: flex;
  align-items: center;
  color: $dark-blue;

  &__icon {
    font-size: 2em;
    margin-right: 5px;
    color: $blue;
  }
}

.row-table-gift-card-wrapper {
  .row-table-gift-card {
    &:nth-child(odd) {
      background-color: #eff8fd;
    }

    &:nth-child(even) {
      background-color: #fff;
    }
    &--footer {
      margin-top: 15px;
      background-color: #fff;
      color: $dark-blue;
    }
    &--footer-mini {
      border-top: 1px solid $dark-blue;
      font-family: Poppins, 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 13px;
      color: $dark-blue;
      &__total {
        margin-right: 11px;
      }
    }
  }
}

.gift-card {
  background-image: linear-gradient(45deg, #3172b3 50%, #79a5cf 100%);
  box-shadow: $box-shadow;
  border-radius: 0;
  color: $white;
  height: 127px;
  width: 230px;
  margin: 20px auto;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  &__logo {
    width: 80px;
    height: 46px;
  }
  &__code {
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: #ffffff;
  }
}
