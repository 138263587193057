.deck-explorer-cabins {
  margin-bottom: 30px;
  &__row {
    margin-bottom: 8px;
  }
}
.cabins-legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &__color {
    margin-right: 7px;
  }
  &__decks {
    padding-left: 30px;
  }
}
.cabin-color {
  display: inline-block;
  height: 18px;
  width: 18px;
  flex: 0 0 18px;
  border-radius: 0;
}
.deck-explorer-cabins-legend {
  border: 1px solid $gray-300;
  padding: 15px;
  border-radius: 0;
}