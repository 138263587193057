.print-quote-modal {
  .row-flex {
    padding-bottom: 15px;
    .col-xs-6 {
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }
  .icon-think-before-print {
    font-size: 2.5em;
    vertical-align: sub;
  }
}