ng-hide.ng-hide-animate {
  display: none !important;
}

.puff-in-center {
  animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
.fade-in-bck {
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.swing-in-top-fwd,
.animation-in {
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
.swing-out-top-bck,
.animation-out {
  animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}
.flip-diagonal-1-bl {
  animation: flip-diagonal-1-bl 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.flip-diagonal-1-tr {
  animation: flip-diagonal-1-tr 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.rotate-0-cw {
  animation: rotate-0-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-0-ccw {
  animation: rotate-0-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-45-cw {
  animation: rotate-45-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-45-ccw {
  animation: rotate-45-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-90-cw {
  animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-90-cw-remove {
  animation: rotate-0-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.rotate-90-ccw {
  animation: rotate-90-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(2px);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes fade-in-bck {
  0% {
    transform: translateZ(80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-bl {
  0% {
    transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-br {
  0% {
    transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-tl {
  0% {
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-tr {
  0% {
    transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-bl {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-tl {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-br {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-tr {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-fwd {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes fade-out-bck {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(-80px);
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-out-top-bck {
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
}
@keyframes flip-diagonal-1-bl {
  0% {
    transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-1-tr {
  0% {
    transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(1, 1, 0, 180deg);
  }
}
@keyframes rotate-0-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate-0-ccw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-0deg);
  }
}
@keyframes rotate-45-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes rotate-45-ccw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate-90-ccw {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.animate-enter,
.animate-leave {
  transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  position: relative;
  display: block;
}

.animate-leave.animate-leave-active,
.animate-enter {
  /*
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
  height: 0px;
*/
  opacity: 0;
}

.animate-enter.animate-enter-active,
.animate-leave {
  /*
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  height: 30px;
*/
  opacity: 1;
}

/* After the transition this will be the only class remaining */
.fadein {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 1; /* Default value but added for clarity */
  &.ng-animate {
    /* Initial state when showing */
    &.ng-hide-remove {
      opacity: 0;
      display: block !important;
      /* Will transition towards this state */
      &.ng-hide-remove-active {
        opacity: 1;
      }
    }
  }
}
.fadeAnimation {
  &.ng-animate {
    /* Initial state when showing */
    &.ng-hide-add {
      animation: fade-out 1s ease-out both;
    }
    &.ng-hide-remove {
      animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }
}
.swingAnimation {
  &.ng-animate {
    /* Initial state when showing */
    &.ng-hide-add {
      animation: 0.5s swing-out-top-bck ease;
    }
    &.ng-hide-remove {
      animation: 0.5s swing-in-top-fwd ease;
    }
  }
}
