@each $state in map-keys($theme-colors) {
  // $alert-background: shift-color($value, $alert-bg-scale);
  // $alert-border: shift-color($value, $alert-bg-scale);
  // $alert-color: shift-color($value, $alert-color-scale);

  // @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
  //   $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
  // }
  .alert-#{$state} {
    // @include alert-variant($alert-background, $alert-border, $alert-color);
    --#{$prefix}alert-color: var(--#{$prefix}#{$state}-text-emphasis);
    --#{$prefix}alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}alert-border-color: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}alert-link-color: var(--#{$prefix}#{$state}-text-emphasis);
    
    border-radius: 0;
  }
}

// @each $state, $value in $theme-colors {
//   $alert-background: $white;
//   $alert-border: #d9d9d6;
//   $alert-color: $dark;
//   $alert-line-width: 6px;
//   @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
//     $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
//   }
//   .alert-#{$state} {
//     @include alert-variant($alert-background, $alert-border, $alert-color);
//     border-radius: $alert-border-radius;
//     &:before {
//       pointer-events: none;
//       content: '';
//       position: absolute;
//       inset: 0 calc(100% - $alert-line-width) 0 0;
//       background: var(--bs-#{$state});
//       border-radius: calc(#{$alert-border-radius} - 1px) 0 0 calc(#{$alert-border-radius} - 1px);
//     }
//   }
// }
