.summary-label {
  display: flex;
  flex-flow: column;
  + .summary-label {
    margin-top: 5px;
  }
  &__intro {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 9px;
    color: $black;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 2px 0 0;
  }
  &__title {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    letter-spacing: -0.5px;
    color: $blue;
    display: block;
    margin-bottom: 5px;
    &--no-margin {
      margin-bottom: 0;
    }
  }
  &__sub-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: $dark-blue;
  }
  &__data {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #000;
  }
}