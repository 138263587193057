.stepper {
/*
  width: 90%;
  max-width: 768px;
*/
  padding: 0.5em 1em;
  margin-bottom: 15px;
  background-color: #edeff0;
  border-radius: 0;
  @media only screen and (min-width: 768px) {
	  background-color: transparent;
    padding: 0;
    text-align: center;
	  li {
	    margin: 1.2em 0;
	    &::after {
		    margin: 0 1em;
		  }
		  > * {
		    font-size: 1.6rem;
		  }
	  }
  }
  &::after {
	  clear: both;
	  content: "";
// 	  display: table;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start
	}
	li {
	  display: inline-block;
	  float: left;
	  margin: 0.5em 0;
	  &::after {
		  /* this is the separator between items */
		  display: inline-block;
		  content: '\00bb';
		  margin: 0 .6em;
		  color: $gray-600;
		}
		&:last-of-type::after {
		  /* hide separator after the last item */
		  display: none;
		}
		> * {
	  	/* single step */
			display: inline-block;
			font-size: .75rem;
			line-height: 120%;
			color: var(--bs-body-color);
		}
		&.current > * {
		  /* selected step */
		  color: $secondary;
		  font-weight: 600;
		}
	}
}

/* --------------------------------

Multi steps indicator

-------------------------------- */
@media only screen and (min-width: 768px) {
  .stepper {
	  li {
	    position: relative;
	    float: left;
	    margin: 0.4em 40px 0.4em 0;
		  &:last-of-type {
		    margin-right: 0;
		  }
		  &::after {
		    /* this is the line connecting 2 adjacent items */
		    position: absolute;
		    content: '';
		    height: 6px;
		    background: $light-blue;
		    /* reset style */
		    margin: 0;
		  }
		  &.visited::after {
		    background-color: $base-blue;
		  }
		  > *,
		  &.current > * {
				position: relative;
				color: $secondary
			}
	  }
	  &.text-bottom {
			li {
		    width: 20%;
		    text-align: center;
		    &::after {
			    /* this is the line connecting 2 adjacent items */
			    position: absolute;
			    left: 50%;
			    /* 40px is the <li> right margin value */
			    width: calc(100% + 40px);
			    top: 3px;
          z-index: -1;
			  }
			  > * {
			    padding-top: 20px;
			    &::before {
				    /* this is the spot indicator */
				    top: 0;
				    /* this is the spot indicator */
				    content: '';
				    position: absolute;
				    z-index: 0;
				    left: 50%;
				    right: auto;
				    transform: translateX(-50%);
				    height: 12px;
				    width: 12px;
				    border-radius: 0;
				    background-color: $base-blue;
				  }
			  }
			  &.visited > *::before,
			  &.current > *::before {
			    background-color: $base-blue;
			    color: #FFF;
			  }
		  }
			a {
				&:hover,
				&:focus {
			    color: $dark-blue;
			    text-decoration: none;
			    &::before {
				    box-shadow: 0 0 0 3px rgba(146, 197, 237, .3);
				  }
			  }
		  }
	  }
  }
}

/* --------------------------------

Add a counter to the multi-steps indicator

-------------------------------- */
.stepper {
	&.count {
		li {
		  counter-increment: steps;
			> *::before {
				content: counter(steps) " - ";
			}
		}
	}
}


@media only screen and (min-width: 768px) {
  .stepper {
	  &.text-bottom {
		  &.count {
			  li {
				  > * {
					  padding-top: 2rem;
					  &::before {
					    /* this is the spot indicator */
					    content: counter(steps);
					    height: 28px;
					    width: 28px;
					    line-height: 21px;
					    font-size: 1em;
					    color: #FFF;
					    border: 3px solid #FFF;
					    font-weight: 600;
              z-index: 0;
					  }
				  }
				  &:not(.current) *::before {
				    /* steps not visited yet - counter color */
				    color: #FFF;
				  }
				  &::after {
				    top: 10px;
				  }
			  }
		  }
	  }
  }
}
