$custom-search-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search text-muted" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>');

@mixin form-search-style() {
  background-image: $custom-search-icon;
  background-size: 16px 14px;
}

.form-search {
  @extend .form-select;
  @include form-search-style();
  &--prepend {
    padding: $input-padding-y $form-select-padding-x $form-select-padding-y $form-select-padding-x * 3;
    background-position: left $form-select-padding-x center;
  }
}

.form-search-sm {
  @extend .form-select-sm;
  @include form-search-style();
}

.form-search-lg {
  @extend .form-select-lg;
  @include form-search-style();
}
