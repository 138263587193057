@import 'src/styles/variables';

.complex-table-wrapper {
  margin-bottom: 15px;

  &--reset-margin {
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;

    [class*='col-'] {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  [class*='col-'] {
    padding-left: 2px;
    padding-right: 2px;

    &:first-child {
      padding-right: 2px;
      padding-left: 15px;
    }

    &:last-child {
      padding-left: 2px;
      padding-right: 15px;

      &:first-child {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    /*
          &:not(:first-child) {
            padding-left: 5px;
            padding-right: 5px;
          }
    */
  }

  .card {
    border-radius: 0;
  }

  .card-heading {
    display: block;
    //padding: 0 15px;
  }

  .card-body {
    padding: 0 15px 15px;
    min-height: 42px;
  }

  .row-table-details {
    &:nth-child(even) {
      .complex-table-row {
        //background-color: #F9F9F9;
        border-bottom: 1px solid $light-grey;

        &:nth-child(even) {
          //background-color: #FFF;
          border-bottom: 1px solid $light-grey;
        }
      }
    }

    &:nth-child(odd) {
      .complex-table-row {
        //background-color: #FFF;
        border-bottom: 1px solid $light-grey;

        &:nth-child(even) {
          //background-color: #F9F9F9;
          border-bottom: 1px solid $light-grey;
        }
      }
    }
  }

  .complex-table-row {
    flex-flow: row wrap;
    //align-items: flex-end;
    border-bottom: 1px solid $light-grey;
    //border-bottom: 1px solid #ddd;
    min-height: 32px;
    white-space: normal;
    padding: 5px 0;

    &--commissions {
      background-color: lighten($light-yellow, 10%) !important;
      color: $dark-gold;
      font-weight: 800;
    }
    font-size: 0.7em;
    @media only screen and (min-width: 992px) {
      font-size: 0.8em;
    }
    @media only screen and (min-width: 1200px) {
      font-size: 0.9em;
    }

    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:nth-child(odd) {
      background-color: #fff;
    }

    &.complex-table-external-header {
      color: $medium-grey;
      font-size: 11px;
      height: 24px;
      margin-bottom: 5px;
      background-color: transparent;
      border: 0;
    }

    &.complex-table-header {
      background-color: $base-blue;
      color: #fff;
      height: 20px;
      border-bottom: 0;

      &--small-typhography {
        font-size: 10px;
      }

      &--big {
        white-space: normal;
        height: auto;
        line-height: 100%;
        padding: 8px 0;
        align-items: center;

        .row-flex {
          align-items: center;
        }
      }
    }

    &.complex-table-row--fake-scroll {
      padding-right: 15px;
    }

    &.complex-table-row--no-overflow {
      [class*='col-'] {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 2px;
      }
    }

    &.complex-table-row--grey {
      color: $base-grey;
    }

    .price {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;

      &__classe {
        flex: 0 1 40px;
        margin-left: auto;
        word-break: break-word;
      }

      &__money {
        margin-left: auto;
        flex: 1 1 auto;
        word-break: break-word;
      }

      &__input {
        margin-top: 0;
        margin-left: auto;
        flex: 0 1 20px;
      }

      input {
        margin-top: 0;
        margin-left: auto;
      }
    }
  }

  .table-download-icon {
    font-size: 1.5em;
  }

  .cabins-price-wrapper {
    border: 1px solid $light-blue;
    border-radius: 0;
    margin: 15px 0;
    overflow: hidden;
    padding: 0 15px;
    box-shadow: 0 0 5px #cecece;

    .complex-table-row {
      border-bottom: 1px solid $light-grey;

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      &:nth-child(even) {
        background-color: #fff;
      }

      > [class*='col-']:first-child {
        padding-left: 50px;
      }

      &--indent-1x {
        > [class*='col-']:first-child {
          padding-left: 50px;
        }
      }

      &--indent-2x {
        > [class*='col-']:first-child {
          padding-left: 75px;
        }
      }

      &--indent-3x {
        > [class*='col-']:first-child {
          padding-left: 100px;
        }
      }
    }
  }

  .guest-price-wrapper {
    [class*='col-']:first-child {
      padding-left: 75px;
    }

    .complex-table-row {
      border-bottom: 1px solid $light-grey;

      &:nth-child(odd) {
        background-color: #fff;
      }

      &:nth-child(even) {
        background-color: #f9f9f9;
      }
    }

    .guest-details {
      .complex-table-row {
        border-bottom: 1px solid $light-grey;

        &:nth-child(odd) {
          background-color: #f9f9f9;
        }

        &:nth-child(even) {
          background-color: #fff;
        }
      }

      section {
        &:not(:last-child) {
          > [class*='col-']:first-child {
            padding-left: 100px;
          }
        }
      }
    }
  }
}

.table {
  background: #fff;

  &--costa {
    border-radius: 0;
    margin-bottom: 0;

    + .table--costa {
      margin-bottom: 20px;
      border-radius: 0;
      overflow: hidden;
    }

    &__header {
      tr {
        th {
          background-color: $base-blue;
          color: $white;
          padding-top: 15px;
          padding-bottom: 10px;
          border-top: 0;

          &:first-child {
            padding-left: 15px;
            border-top-left-radius: 0;
          }

          &:last-child {
            padding-right: 15px;
            border-top-right-radius: 0;
          }
          &.pricecolumn {
            white-space: nowrap;
          }
        }
      }
    }

    &__pre-header {
      tr {
        th {
          font-size: 11px;
          color: #999;
          border: 0;
          padding-bottom: 2px;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }
        }
      }
    }
  }

  &--shadow {
    box-shadow: $box-shadow;
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    border: 1px solid #f6f6f6;
    thead {
      tr {
        color: #0071a3;
      }
    }
  }

  &--sm {
    font-size: 11px;
  }
  &--clients {
    > thead {
      tr {
        th {
          width: 10%;
          vertical-align: baseline;
          border-right: 1px solid #fff;
        }
      }
    }
    > tbody {
      &:nth-child(odd) {
        background-color: #e7f0f8;
      }
      &:nth-child(even) {
        background-color: #fff;
      }
      tr {
        td {
          vertical-align: middle;
          border-right: 1px solid #d1d1d1;
        }
        &:nth-child(odd) {
          background-color: transparent;
        }
        &:nth-child(even) {
          background-color: transparent;
        }
        + tr {
          td {
            &:first-child {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}

.table {
  &--no-border {
    tr td,
    tr th {
      border-width: 0;
    }
  }

  &--center {
    > thead,
    > tbody {
      tr td,
      tr th {
        text-align: center;
      }
    }
  }

  &--shadow {
    box-shadow: $box-shadow;
  }

  &--small-font {
    font-size: 11px;
  }
}

.table-practical-details {
  &__indicator {
    display: inline-block;
    width: 22px;
    background: #fff;
    border-radius: 0;
  }

  &__icon {
    font-size: 21px;
    color: $base-yellow;
  }

  &__pagination,
  &__cta {
    margin: 5px 0;
    text-align: left;
  }

  &__page-indicator {
    padding: 5px 20px !important;
    border-radius: 0;
    font-size: 13px;
    text-transform: uppercase;
  }
}

.jump-table {
  position: relative;

  table {
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
  }

  &:after {
    content: '';
    display: block;
    height: 75px;
    width: 100%;
    background: #0071a3;
    top: -15px;
    border-bottom-left-radius: 0;
  }

  tr th {
    border-left: 1px solid white;
    border-bottom: 1px solid #fff;
    vertical-align: middle;
    position: relative;

    &:first-child {
      border-left: 0;
    }
  }

  tr td {
    border-left: 1px solid #d8d8d8;
    vertical-align: middle;
    position: relative;

    &:first-child {
      border-left: 0;
    }
  }

  tr {
    &.second-header {
      td {
        padding: 0;
        vertical-align: middle;
      }
    }
  }

  .jump-table-icon {
    color: $green;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 14px;
  }

  .jump-table-star {
    position: absolute;
    right: 18px;
    top: 13px;
    color: $base-yellow;
    font-size: 18px;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #e7f0f8;
}

.complex-table-row {
  &__oheader {
    &--active {
      font-weight: 600;

      .complex-table-row__header-label {
        color: $dark-blue;
      }
    }
  }

  &__header-label {
    cursor: pointer;
    color: $gray-600;
  }

  &__order-icon {
    color: $dark-blue;
  }

  &__body-label {
    &--type {
      text-transform: uppercase;
    }
  }

  &:nth-child(even) {
    .complex-table-row {
      &__body-label {
        &--title {
          @include multiLineEllipsis($lineHeight: 1.4em, $lineCount: 2, $bgColor: #fff);
        }
      }
    }
  }

  &:nth-child(odd) {
    .complex-table-row {
      &__body-label {
        &--title {
          @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: #f9f9f9);
        }
      }
    }
  }
}
