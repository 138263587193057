.modal {
  font-size: 13px;

  .modal-aside {
    transform: translate(0);
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100vh;
    width: 33%;
    margin: 0;

    .modal-content {
      height: 100vh;
      overflow: auto;
    }
  }

  &.modal-2021 {
    .modal-content {
      // background: #ffffff;
      // box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.15);
      // border-radius: 0;
      // border: none;
      // padding: 0.938em;
    }

    .modal-header {
      &__close {
        font-size: 1.313em;
        line-height: 1;
        color: #000;
        opacity: 0.35;
        margin-top: -10px;
        margin-right: -10px;
      }

      .modal-title {
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 2.875em;
        margin-bottom: 2rem;
        color: $blue;
      }
    }

    .modal-body {
      section {
        margin-bottom: 30px;
      }

      &__title {
        font-size: 1em;
        font-family: Poppins, sans-serif;
        font-style: normal;
      }

      &__p {
        max-width: 740px;
      }
    }
  }
}

.col-group {
  .col {
    border-radius: 0;
    border: 1px solid #d3d3d3;

    ~ .col {
      border-radius: 0;
      border-left: 0;
      background-color: rgba($yellow, 0.1);
    }
    &.col-ita {
      background-color: #fff;
      border: 0;
    }
  }
}

.col-ita {
  // margin-right: 1%;
  .top-column {
    border: 1px solid #77d1ed;
    &.gold-outline {
      outline: 5px double gold;
      z-index: 999;
      border: 0;
      position: relative;
    }
    min-height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .card-package-cta {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      a {
        width: 70%;
      }
    }

    .best-offer {
      position: absolute;
      top: -8px;
      left: 30%;
      color: #0071a3;
      background: white;
      border: 1px solid #0071a3;
      padding: 0px 40px;
      font-size: 11px;
      font-weight: 800;
    }

    .card-package-top {
      text-align: center;
      background: #e6f4ff;
      height: 156px;
      padding-top: 25px;
    }

    .package-title {
      font-weight: bold;
      color: #0071a3;
      font-size: 130%;
    }

    .package-composition-wrapper {
      background: white;
      margin: 0 auto;
      height: 110px;
      margin-top: 10px;
      box-shadow: 0px 0px 10px 0px #cecece;

      &__single {
        width: 70%;
        .box-top {
          border-bottom: 1px solid #cecece;
          height: 68px;
          width: 100%;
          float: left;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
        }
      }

      &__double {
        width: 70%;
        .box-top-left {
          border-bottom: 1px solid #cecece;
          height: 68px;
          border-right: 1px solid #cecece;
          width: 50%;
          float: left;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
        }
        .box-top-right {
          border-bottom: 1px solid #cecece;
          height: 68px;
          width: 50%;
          float: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
          &:before {
            content: '\e1942';
            font-family: 'costaglyph';
            left: 48%;
            position: absolute;
            z-index: 9999;
            background: white;
          }
        }
      }

      &__triple {
        width: 80%;
        .box-top-left {
          border-bottom: 1px solid #cecece;
          height: 68px;
          border-right: 1px solid #cecece;
          width: 33.33333%;
          float: left;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
        }
        .box-top-center {
          border-bottom: 1px solid #cecece;
          height: 68px;
          border-right: 1px solid #cecece;
          width: 33.33333%;
          float: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
          &:before {
            content: '\e1942';
            font-family: 'costaglyph';
            left: 35%;
            position: absolute;
            z-index: 9999;
            background: white;
          }
        }
        .box-top-right {
          border-bottom: 1px solid #cecece;
          height: 68px;
          width: 33.33333%;
          float: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          .card-package__list-element-icon {
            width: 25px;
            padding-bottom: 8px;
          }
          &:before {
            content: '\e1942';
            font-family: 'costaglyph';
            left: 61.5%;
            position: absolute;
            z-index: 9999;
            background: white;
          }
        }
      }

      .box-bottom {
        width: 100%;
        float: left;
        padding: 11px;
        color: #0071a3;
        small {
          margin-right: 5px;
        }
        big {
          font-weight: 800;
          color: #009dd3;
        }
      }
    }

    .card-package-bottom {
      padding: 25px 12px;
      font-size: 11px;
      height: 400px;
      .box-top {
        height: 120px;
      }

      .box-center {
        margin-bottom: 10px;
      }

      .bottom {
        //nascondi saving
        //display:none;
      }

      .box-list {
        .box-list-item {
          display: flex;
          align-items: baseline;
          flex-direction: row;
          height: auto;
          border-bottom: 1px solid #cecece;
          margin-bottom: 15px;
          .box-list-icon {
            img {
              width: 21px;
              height: 20px;
              filter: invert(28%) sepia(39%) saturate(7393%) hue-rotate(181deg) brightness(87%) contrast(101%);
            }
            &__plus:before {
              //content: "\e1996";
              //font-family: "costaglyph";
              //left: 43%;
              //top: 55.8%;
              //position: absolute;
              //color: #009dd3;
              //font-size: 13px;
            }
            &__plus_single:before {
              //content: "\e1996";
              //font-family: "costaglyph";
              //left: 43%;
              //top: 76.8%;
              //position: absolute;
              //color: #009dd3;
              //font-size: 13px;
            }
          }
          .box-list-center {
            width: 88%;
            &__title {
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            &__text {
              font-size: 90%;
            }
          }
          .box-list-checkmark {
            i.bi-check {
              color: #009dd3;
              font-size: 18px;
            }
          }
        }
        .saving {
          background: white;
          position: relative;
          top: -25px;
          left: 115px;
          color: #f85420;
          width: 120px;
          padding: 2px;
          //nascondi saving
          //display:none;
          img {
            float: left;
            height: 15px;
            filter: invert(38%) sepia(40%) saturate(2728%) hue-rotate(349deg) brightness(100%) contrast(96%);
            margin-right: 5px;
          }
          p {
            margin: 0;
            font-weight: 600;
          }
        }
        .saving-drinks {
          background: rgba(var(--bs-promo-rgb))!important;
          position: relative;
          top: -25px;
          left: 115px;
          color: #fff!important;
          width: 220px;
          padding: 2px;
          img {
            float: left;
            height: 15px;
            filter: invert(84%) sepia(90%) saturate(2699%) hue-rotate(312deg) brightness(100%) contrast(96%);
            margin-right: 5px;
          }
        }
      }
    }
  }

  .bottom-column {
    background-color: rgba($yellow, 0.1);
    display: flex;
    flex-flow: column;

    .plus-explanation {
      padding: 15px;
      &__left {
        float: left;
        width: 20%;
        img {
          width: 34px;
          height: 42px;
          filter: none;
          filter: invert(28%) sepia(39%) saturate(7393%) hue-rotate(181deg) brightness(87%) contrast(101%);
        }
      }
      &__right {
        float: left;
        width: 80%;
        &--title {
          font-size: 18px;
          font-weight: 800;
          margin-bottom: 10px;
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.card-package {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
  }

  &__title {
    font-family: Poppins, sans-serif;
    font-weight: 800;
    font-size: 20px;
    color: $blue;
  }

  &__body {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 250px;
    min-height: 250px;
  }

  &__footer {
  }

  &__price {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    font-size: 13px;
    &-value {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 26px;
    }

    small {
      display: block;
      height: 60px;
    }
  }

  &__list {
    padding-left: 0;
    list-style: none;

    &-element {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 56px;

      border-bottom: 1px solid #d3d3d3;
      padding: 18px 10px;

      &-icon {
        margin-right: 7px;
      }

      &-info {
        display: flex;
        flex-flow: column nowrap;

        b {
          font-size: 13px;
          font-weight: 600;
        }

        span {
          opacity: 0.75;
          font-size: 10px;
          color: $gray-600;
        }
      }

      &-indicator {
        margin-left: auto;
        .icon {
          color: #9e9691;
        }
        .bi {
          color: $green;
        }
      }
    }
  }

  &__badge {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 11px;
    background-color: #fff;
    padding: 4px 10px;
    margin-top: 16px;
    margin-bottom: 16px;
    align-self: flex-end;

    &--blue {
      color: $dark-blue;
      border: 1px solid $dark-blue;
    }

    &--red {
      color: $red;
      border: 1px solid $red;
    }
  }

  &__icon {
    height: 42px;
    width: 42px;
    filter: invert(25%) sepia(75%) saturate(5000%) hue-rotate(191deg) brightness(95%) contrast(87%);
  }
}
