.summary-adv {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  color: $black;
  text-align: right;
  &__name {
    font-family: Poppins, "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: $gray-600;
    line-height: 18px;
    margin-bottom: 10px;
  }
  &__mail {
    font-family: Poppins, "Open Sans", sans-serif;
    margin-bottom: 5px;
  }
  &__telephone {
    font-family: Poppins, "Open Sans", sans-serif;
  }
}