@import 'src/styles/variables';

.banner-mycosta {
  font-family: Poppins, "Open Sans", sans-serif;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid;
  background-color: #FFF;
  padding: 25px 20px 10px;
  border-radius: 0;
  box-shadow: $box-shadow;
  max-width: 250px;
  margin: 0 auto;
  &--border-blue {
    border-color: $blue;
  }
  &__text {
    font-size: 16px;
    color: $dark-blue;
    letter-spacing: 0.2px;
    text-align: center;
    line-height: 23px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__cta {
    padding: 0 30px;
    margin-bottom: 15px;
  }
  &__footer-text {
    font-size: 8px;
    color: $dark-blue;
    text-align: center;
  }
}
