.summary-header {
  display: flex;
  flex-flow: row wrap;
  background-color: #FAFAFA;
  padding: 20px 8.8% 10px;
  &__logo {
    background-position: 0 0;
    background-size: contain;
    background-repeat: no-repeat;
    height: 85px;
    width: 50%;
  }
  &__adv-info {
    width: 50%;
    text-align: right;
    margin-top: 10px;
  }
  &__details {
    width: 100%;
  }
  &__title {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    color: $blue;
  }
}