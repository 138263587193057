.services-container {
  // border: 1px solid $light-grey;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.transportation-cards-container {
  margin-top: 15px;
  .transportation {
    &.disabled {
      background: $white;
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
    .card-transportation {
      min-height: 365px;
      border: 1px solid $light-grey;
      .card-body {
        border: 0;
      }
      hr.mini {
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .transportation-elements {
        border-bottom: 1px solid #ddd;
        &:nth-child(3) {
          border-bottom: 0;
        }
      }
      .transportation-icons-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .btn {
          width: 33%;
          height: 73px;
          background-color: #fff;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: space-around;
          padding: 0px 5px;
          border: 1px solid #0071a3;
          margin: 0 0.125rem;
          font-size: 11px;
          line-height: 1;
          font-weight: 600;
          transition: background-color 0.33s ease-out;
          &:focus {
            outline: none;
          }
          &:active {
            box-shadow: none;
            color: $medium-blue;
            transition: 0.3s color ease-in;
          }
          &:hover {
            background-color: #e3eff7;
            transition: background-color 0.33s ease-in;
            color: $blue;
          }

          &.active {
            background-color: $blue;
            box-shadow: none;
            color: #fff;
            &:before {
              padding: 0;
              color: #fff;
            }
          }
          &.disabled {
            color: #a6a6a6;
            border: 1px solid #b1b1b1;
            &:before {
              color: #a6a6a6;
            }
          }
          &:before {
            display: inline-block;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            text-rendering: auto;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: greyscale;
            color: $base-blue;
            height: 38px;
            width: 38px;
            position: inherit;
            z-index: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
          &.btn-flight {
            &:before {
              font-family: 'bootstrap-icons' !important;
              content: '\F7CD';
              font-size: 1.8em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &.btn-train {
            &:before {
              font-family: 'bootstrap-icons' !important;
              content: '\F81D';
              font-size: 1.8em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &.btn-flight-train {
            &:before {
              font-family: 'CostaOTO';
              content: '\e93c';
              font-size: 2.8em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &.btn-flight-bus {
            &:before {
              font-family: 'CostaOTO';
              content: '\e945';
              font-size: 2.8em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &.btn-bus {
            &:before {
              font-family: 'bootstrap-icons' !important;
              content: '\F87F';
              font-size: 1.8em;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .form-transportation {
        margin-bottom: 0;
      }
    }
    &.transportation-mandatory {
      .card-body {
        border-bottom: 5px solid $base-yellow;
      }
      .bi-asterisk {
        font-size: 1.5em;
        margin-top: -2px;
      }
      .transportation-icons-wrapper {
        .btn {
          transition: background 0.5s ease-out;
          width: 33%;
          height: 73px;
          &.active {
            box-shadow: none;
            &:before {
              border: 2px solid $base-blue;
              background-color: $base-yellow;
              border-radius: 4px;
              padding: 12px 16px;
              color: $base-blue;
              transition: background 0.5s ease-in;
            }
          }
        }
      }
    }
  }
}
