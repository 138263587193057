@import 'mixin/ellipsis';

@import 'variables';

@import 'ng-select-bootstrap';
@import 'form-datepicker';
@import 'form-search';
@import 'cc-facilities-icons';
@import 'form-search';

// OLD Styles
//@import 'old/alert-box';
@import 'old/animations';
@import 'old/article';
@import 'old/banner-mycosta';
@import 'old/btn-costa';
@import 'old/c_modal-cclub';
@import 'old/c-aside';
@import 'old/c-cruise';
@import 'old/calendar_view';
@import 'old/cards';
@import 'old/charts';
@import 'old/common';
@import 'old/cookie';
@import 'old/data-paragraph';
@import 'old/deck-explorer';
@import 'old/details';
@import 'old/fares_images';
@import 'old/forms';
@import 'old/gift-card';
@import 'old/group-cabins-selector';
@import 'old/group-type-list';
@import 'old/icons';
@import 'old/jumbointro';
@import 'old/ldm-stepper';
@import 'old/lists';
@import 'old/loader';
@import 'old/logo';
@import 'old/modals-2021';
@import 'old/modals-promo';
@import 'old/modals';
@import 'old/no-responsive';
@import 'old/otofonts';
@import 'old/print';
@import 'old/progress';
@import 'old/result';
@import 'old/ship-icons';
@import 'old/shorex-itinerary';
@import 'old/shorex-row';
@import 'old/summary-adv';
@import 'old/summary-banner';
@import 'old/summary-body';
@import 'old/summary-header';
@import 'old/summary-label';
@import 'old/summary-list';
@import 'old/summary-table';
@import 'old/summary';
@import 'old/table-booking-report';
@import 'old/table-group-request';
@import 'old/table-status-indicator';
@import 'old/tables';
@import 'old/tabs';
@import 'old/tooltip';
// @import 'old/wecare';
@import 'old/costaglyph';
@import 'old/sharepointfix';

@import 'old/partials/cruise-fares-table';

@import 'old/partials/cruise-element';
@import 'alert';

.text-muted {
  color: $body-secondary-color !important;
}

::marker {
  color: $link-color;
  // font-size: 1.2rem;
}

:root {
  // Navigation
  --swiper-navigation-size: 24px;
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: var(--swiper-theme-color);
  // Pagination
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

// * {
//   &::-webkit-scrollbar {
//     width: 16px;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: $body-bg;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: $gray-600;
//     transition: background-color 0.3s ease-out;
//     &:hover {
//       background-color: $gray-800;
//       transition: background-color 0.3s ease-in;
//     }
//   }
//   scrollbar-color: $body-color $body-bg;
//   transition: scrollbar-color 0.3s ease-out;
//   &:hover {
//     scrollbar-color: $gray-800;
//     transition: background-color 0.3s ease-in;
//   }
// }

ol,
ul {
  padding-left: 1rem;
}

p,
small,
label {
  letter-spacing: 0.02em;
}

b,
strong {
  font-weight: 600;
}

a[onclick] {
  cursor: pointer;
}

.btn-white {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: var(--bs-white);
  --bs-btn-border-color: var(--bs-dark);
  --bs-btn-hover-color: var(--bs-dark);
  --bs-btn-hover-bg: #{shade-color($white, 10%)};
  --bs-btn-hover-border-color: #{shade-color($dark, 10%)};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($white, 20%)};
  --bs-btn-active-border-color: #{shade-color($dark, 20%)};
  &.active {
    background: $link-color;
    color: $white;
  }
}

.btn-outline-dark {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-dark);
  --bs-btn-hover-color: #{shade-color($dark, 5%)};
  --bs-btn-hover-bg: #{shade-color($light, 5%)};
  --bs-btn-hover-border-color: #{shade-color($dark, 10%)};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: transaprent;
  --bs-btn-active-border-color: #{shade-color($dark, 20%)};
  &.active {
    border-color: $link-color;
    box-shadow: none;
  }
}

.btn-outline-light {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-border-color);
  --bs-btn-hover-color: var(--bs-dark);
  --bs-btn-hover-bg: #{shade-color($light, 5%)};
  --bs-btn-hover-border-color: #{shade-color($border-color, 10%)};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: transaprent;
  --bs-btn-active-border-color: #{shade-color($border-color, 20%)};
  &.active {
    border-color: $link-color;
    box-shadow: none;
  }
}

/* TEXT COLORS */
.text-secondary-light {
  color: $light-blue;
}

.text-secondary-medium {
  color: $medium-blue;
}

.text-dark-blue {
  color: $dark-blue;
}

.text-gold {
  color: $base-gold;
}

.text-dark-gold {
  color: $dark-gold;
}

/* TEXT COLORS END */
.background-cream {
  background-color: #f1f0ee;
}

.background-blue-light {
  background-color: #eff8fd;
}

.table-cell {
  &__breakword {
    overflow: hidden;
    word-wrap: break-word;
  }
}

.usedLabels {
  z-index: 100;
  background-color: $red;
  position: fixed;
  top: 10px;
  left: 5px;
  width: 150px;
  float: left;
  text-align: center;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  border-radius: 0;
  color: white;
  padding: 10px 15px;
  border: 0;
  overflow: hidden;
}

.usedLabels a,
.usedLabels a:active {
  color: white;
}

.costaclub-toggle-selected {
  border: 1px solid $link-color;
}

.bi.visible-on-costaclub-toggle-selected {
  visibility: hidden;
}

.costaclub-toggle-selected .bi.visible-on-costaclub-toggle-selected {
  visibility: visible;
  // background: #0f72b9;
  color: $link-color;
  font-size: 130%;
  position: absolute;
  top: 4px;
  left: 4px;
}

.blurred {
  pointer-events: none;
  user-select: none;
  filter: blur(10px);
}

.vax-disclaimer {
  display: none;
}

.magnify-container .magnify-glass {
  width: 900px;
  height: 400px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

/* CATEGORY NEW */

.well {
  min-height: 20px;
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: map-get($spacers, 3);
  border: 1px solid $border-color;
  border-radius: 4px;
  background: linear-gradient(0deg, #e6e9f0 0%, #eef1f5 100%);
}

.modal-header .btn-close {
  margin-left: auto;
}

.nav-tabs {
  &.bg-secondary {
    .nav-item {
      padding-top: 4px;
    }

    a {
      color: $white;

      &.active {
        color: $dark-blue;
      }
    }
  }
}

.pagination {
  .page-link {
    &:has(i) {
      padding: 7px var(--bs-pagination-padding-x);
    }
  }
}

.accordion-button {
  &.bg-secondary {
    &:after {
      filter: brightness(0) invert(1);
    }
  }
}

.select-w-fixed {
  min-width: 140px;
}

// .card.shadow > * > .card.shadow {
//   box-shadow: none !important;
// }

.form-control {
  &[readonly] {
    @extend .form-control, :disabled;
  }
  &.ng-touched {
    // &.ng-valid {
    //   @extend .is-valid;
    // }
    &.ng-invalid {
      @extend .is-invalid;
    }
  }
}

.btn-costa--yellow {
  @extend .btn-primary;
}
.btn-costa--blue {
  @extend .btn-secondary;
}
.text-blue {
  @extend .text-secondary;
}

.table-fixed {
  table-layout: fixed;
}

table.table {
  thead {
    tr {
      th {
        font-weight: 600;
      }
    }
  }
}

// Questa classe serve sistemare il padding della tabella dentro una card
// Gerarchia: .card > .card-table
.card-table {
  thead,
  tbody {
    tr {
      // Applica il padding sinistro alla prima cella (sia per th che per td)
      th:first-child,
      td:first-child {
        padding-left: $card-spacer-x;
      }

      // Applica il padding destro all'ultima cella (sia per th che per td)
      th:last-child,
      td:last-child {
        padding-right: $card-spacer-x;
      }
    }
  }
}

.form-switch .form-check-input {
  border-radius: 1rem;
}
.popover-xl {
  max-width: 450px;
}

.tooltip {
  .tooltip-inner {
    box-shadow: $box-shadow;
    max-width: 270px !important;
    width: max-content !important;
  }
}

.list-check {
  list-style-type: none;
  padding: 0;
  li {
    padding-left: 16px;
    position: relative;
    &:before {
      color: var(--bs-link-color);
      content: '\F272';
      font-family: bootstrap-icons !important;
      left: 0;
      position: absolute;
      top: 1px;
    }
    &.is-invalid::before {
      content: '\F659';
      color: var(--bs-danger);
    }
  }
}

.form-label {
  line-height: 0.8125rem; /* 100% */
  text-transform: uppercase;
}

.mw-btn {
  min-width: 170px;
}

.alert {
  font-size: .875rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  background:#e9ecef;
  color: #afb5bd;
  cursor:default;
}
