aside {
  //border-bottom: 4px solid $medium-blue;
  width: 100%;
  padding-bottom: 15px;
  // @media only screen and (min-width: 1200px) {
  //   width: 285px;
  // }
  &.panel-multi {
    position: relative;
    margin-bottom: 27px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 38px;
    }
    @media only screen and (min-width: 1200px) {
      margin-bottom: 51px;
    }
    &:after {
      position: absolute;
      content: ' ';
      left: 0;
      right: 0;
      background-image: url(/Style%20Library/images/aside-multi.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 57px;
      width: 100%;
      z-index: 10;
    }
    &:nth-child(2) {
      background-image: none;
    }
  }
  .map-wrapper {
    .map {
      background: center no-repeat;
      background-size: cover;
      display: block;
      /*height: 130px;
      width: 130px;*/
      padding-top: 100%;
      width: 100%;
      border-radius: 0;
      border: 1px double #d0e3f1;
      @media only screen and (min-width: 992px) {
        border: 2px solid #d0e3f1;
      }
      @media only screen and (min-width: 1200px) {
      }
    }
  }
  p {
    //font-size: 0.846em; //11px;
    a {
      text-decoration: underline;
    }
    &.description {
    }
    &.madatory-documents {
    }
  }
  .price-wrapper {
    margin-top: 5px;
  }
}
.asideAffix {
  width: 100%;
  max-width: 130px;
  @media only screen and (min-width: 768px) {
    max-width: 175px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 230px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 230px;
  }
}
