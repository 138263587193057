.cck-page .complex-table-wrapper .panel-body.table-group-min-height {
  min-height:215px;
}

.brochure-request-table {
  font-size:11px;
  thead {
    text-align: center;
    padding: 5px;
    background-color: #063C5D;
    border: 1px solid #FFF;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
  }
  thead > tr > th {
    width: 25%;
    padding:10px;
    text-align: center;
  }

  tbody > tr:nth-child(odd) {
    background-color: $white;
  }
  tbody > tr:nth-child(even) {
    background-color: lighten($light-blue, 15%);
  }
  tbody > tr > td {
    width: 25%;
    padding:5px;
  }
}
.table-group-request {
  margin-bottom: 15px;
  &--1-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  &--2-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 50%;
          max-width: 50%;
        }
      }
    }
  }

  &--3-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 33.333333%;
          max-width: 33.333333%;
        }
      }
    }
  }

  &--4-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 25%;
          max-width: 25%;
        }
      }
    }
  }

  &--5-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 20%;
          max-width: 20%;
        }
      }
    }
  }

  &--8-col {
    .table-group-request {
      &__header,
      &__body-row {
        &--cell {
          flex: 1 0 calc(100% / 8);
          max-width: calc(100% / 8);
        }
      }
    }
  }


  &__intro-header {
    margin-bottom: 5px;
  }

  &__intro-label {
    font-weight: 600;
  }

  &__intro-header-col {

  }

  &__header {
    display: flex;
    flex-flow: row;
    width: 100%;

    &--cell {
      text-align: center;
      padding: 5px;
      background-color: #063C5D;
      border: 1px solid #FFF;
      color: #FFF;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (min-width: 992px) {
        height: 38px;
      }
    }

    &--cell-2 {
      flex: 1 0 25%;
      max-width: 25%;
    }
    &--cell-3{
      flex: 1 0 16.6666%;
      max-width: 16.6666%;
    }

    + .table-group-request__body {
      border-top: 0;
      &--scrollable {
        border-top: 0;
        max-height: 250px;
        overflow-y: auto;
      }

    }

  }

  &__body {
    border: 1px solid $light-grey;
    margin: 1px;
  }

  &__body-row {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid $light-grey;

    &:nth-child(odd) {
      background-color: $white;
    }

    &:nth-child(even) {
      background-color: lighten($light-blue, 15%);
    }

    &:last-of-type {
      margin-bottom: 5px;
      border-bottom: 0;
    }

    &.selected {
      background-color: $yellow;
    }

    &--cell {
      height: 36px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      > .table-status-indicator {
        margin-left: 7px;
      }

    }

    &--cell-start {
      justify-content: flex-start;
    }

    &--cell-end {
      justify-content: flex-end;
    }

  }

  &__cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 6px;
  }


  &__col-pr0 {
    padding-right: 0;
  }

  &__col-pl0 {
    padding-left: 0;
  }

  &__col-pr5 {
    padding-right: 5px;
  }

  &__col-pl5 {
    padding-left: 5px;
  }
}