/*
  Blocco
*/
.fares-wrapper {
  position: relative;
  width: 100%;
  height: 56.25%;
  overflow: hidden;
  //&:before {
  //  content: "";
  //  display: block;
  //  padding-top: 56.25%;
  //  width: 100%;
  //}

  /*
    Blocco--Modificaroti
  */
  &--size-xs {
    height: 18px;
    width: 60px;
    flex: 0 0 60px;
  }
  &--size-md {
    height: 22px;
    width: 75px;
    flex: 0 0 75px;
  }
  &--size-lg {
    height: 34px;
    width: 115px;
    flex: 0 0 115px;
  }
  &--size-xs-icon {
    height: 16px;
    width: 14px;
    flex: 0 0 14px;
  }
  &--size-md-icon {
    height: 22px;
    width: 20px;
    flex: 0 0 20px;
  }
  &--size-lg-icon {
    height: 34px;
    width: 34px;
    flex: 0 0 34px;
  }
  /*
    Blocco--Modificaroti END
  */

  /*
    Blocco__Elemento
  */
  &__image {
    background: center no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    &--basic {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/basic.png');
    }
    &--best_price {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/best_price.png');
    }
    &--ind {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/best_price.png');
    }
    &--best_price_china {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/best_price_china.png');
    }
    &--flash {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/flash.png');
    }
    &--group {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/group.png');
    }
    &--colectiv {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/quota_netta.png');
    }
    &--pind {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/last_minute.png');
    }
    &--costa {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/total_comfort.png');
    }
    &--cfp {
      // Spazio famiglie total comfort
      background-image: url('/Style%20Library/CostaClickNew/images/fares/total_comfort.png');
    }
    &--chm {
      // Viaggio di nozze total comfort
      background-image: url('/Style%20Library/CostaClickNew/images/fares/total_comfort.png');
    }
    &--suite {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/deluxe.png');
    }
    &--travelag {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/travel_e_friends.png');
    }
    &--ebasic {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/ebasic.png');
    }
    &--light {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/light.png');
    }
    &--efp {
      // Spazio famiglie total comfort
      background-image: url('/Style%20Library/CostaClickNew/images/fares/ecosta.png');
    }
    &--ehm {
      // Viaggio di nozze total comfort
      background-image: url('/Style%20Library/CostaClickNew/images/fares/ecosta.png');
    }
    &--ecosta {
      background-image: url('/Style%20Library/CostaClickNew/images/fares/ecosta.png');
      &--suite {
        background-image: url('/Style%20Library/CostaClickNew/images/fares/suite.png');
      }
    }
  }
  /*
    Blocco--Elemento END
  */
}
/*
  Blocco END
*/
