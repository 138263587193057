.list-group {
  &--costa {
    &__element {
      border: 0;
      &:nth-child(odd) {
        background-color: #fff;
      }
      &:nth-child(even) {
        background-color: $light-grey;
      }
    }
  }
}
