.summary-banner {
  background: #FAFAFA;
  border-radius: 0;
  padding: 10px 15px 10px;
  font-family: Poppins, sans-serif;
  font-size: 30px;
  letter-spacing: -0.5px;
  color: $blue;
  margin-bottom: 15px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  &__title {
    width: 50%;
  }
  &__price {
    width: 50%;
    text-align: right;
  }
  &__cta {
    width: 100%;
    font-family: Poppins, "Open Sans", sans-serif;
    font-style: italic;
    font-size: 16px;
    text-align: right;
  }
}