.breadcrumbs {
  &--ldm {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    max-width: 150px;
  }
  &__label {
    font-weight: 600;
    font-size: 13px;
    color: #063C5D;
    letter-spacing: 0.3px;
    flex: 0 0 auto;
  }
  &__list {}
}

.stepper {
  list-style-type: none;
  margin-bottom: 0;
  &--ldm {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 100%;
    padding-left: 7px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      height: 1px;
      background: $light-blue;
      margin: 0;
      left: 15%;
      width: 75%;
      z-index: 0;
    }
    &__element {
      flex-basis: 25%;
      text-align: center;
      z-index: 1;

    }
  }
}

.stepper-icon {
  background: white;
  cursor: default;
  color: $light-blue;
  &__visited {
    color: $dark-blue;
    //cursor: pointer;
  }
  &__current {
    color: $blue;
    text-shadow: 0 0 4px rgba(16,114,185,.50);
    animation-name: pulse;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

}