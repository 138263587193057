@import "summary-header";
@import "summary-body";
@import "summary-label";
@import "summary-adv";
@import "summary-table";
@import "summary-list";
@import "summary-banner";

.summary {
  font-family: Poppins, "Open Sans", sans-serif;
  max-width: 970px;
  height: 100%;
  //height: 1245px;
  background-color: $white;
  padding: 0;
  &-separator {
    border-top: 1px solid #D1CCCC;
    width: 100%;
    margin: 5px 0;
  }
}