/*
  Blocco
*/
.cruise-icon {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  &--size-xs {
    height: 28px;
    width: 28px;
  }
  &--size-md {
    height: 46px;
    width: 46px;
  }
  &--size-lg {
    height: 75px;
    width: 75px;
  }
  &--size-xs-icon {
    height: 16px;
    width: 14px;
  }
  &--size-md-icon {
    height: 22px;
    width: 20px;
  }
  &--size-lg-icon {
    height: 34px;
    width: 34px;
  }
  &--circled {
    background-color: $gray-300;
    border-radius: 0;
  }
  &--shadow {
    box-shadow: $box-shadow;
  }
  &__image {
    background: no-repeat 50% 50%;
    background-size: 70%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    &--atlantica {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_atlantica.png');
    }
    &--deliziosa {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_deliziosa.png');
    }
    &--diadema {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_diadema.png');
    }
    &--fascinosa {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_fascinosa.png');
    }
    &--favolosa {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_favolosa.png');
    }
    &--fortuna {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_fortuna.png');
    }
    &--luminosa {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_luminosa.png');
    }
    &--magica {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_magica.png');
    }
    &--mediterranea {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_mediterranea.png');
    }
    &--neoclassica {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_neoclassica.png');
    }
    &--neoriviera {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_neoriviera.png');
    }
    &--neoromantica {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_neoromantica.png');
    }
    &--pacifica {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_pacifica.png');
    }
    &--smeralda {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_smeralda.png');
    }
    &--venezia {
      background-image: url('/Style%20Library/CostaClickNew/images/ship_icons/costa_venezia.png');
    }
  }
}
