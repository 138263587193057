.tiers-drawer {
  padding: 0 16px;
}

.tiers-drawer * {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tiers-drawer .tiers-drawer__upper-title {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.21875rem;
  letter-spacing: 0.02em;
  color: #292929;
}

.tiers-drawer .tiers-drawer__title {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1.5625rem;
  line-height: 2.1875rem;
  letter-spacing: 0;
  color: #292929;
  margin: 8px 0;
}

.tiers-drawer .tiers-drawer__disclaimer {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: #292929;
  margin-bottom: 32px;
  margin-top: 22px;
}

.tiers-drawer .tiers-drawer__disclaimer > p a {
  color: #292929;
  text-decoration: underline;
  cursor: pointer;
}

.tiers-drawer .tiers-drawer__filter-title {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__filter-bar {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-x: auto;
  margin: 24px -16px 0;
  padding: 0 16px 16px;
}

.tiers-drawer .tiers-drawer__filter-bar::-webkit-scrollbar {
  display: none;
}

.tiers-drawer .tiers-drawer__filter-bar > span {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  color: #63666a;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 16px;
}

.tiers-drawer .tiers-drawer__filter-wrapper {
  margin-left: 7px;
  position: relative;
}

.tiers-drawer .tiers-drawer__filter-wrapper .tiers-drawer__filter-checkbox {
  inset: 0;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 2px;
}

.tiers-drawer .tiers-drawer__filter-wrapper .tiers-drawer__filter-checkbox:checked + .tiers-drawer__filter-item {
  border-color: #292929;
}

.tiers-drawer
  .tiers-drawer__filter-wrapper
  .tiers-drawer__filter-checkbox:checked
  + .tiers-drawer__filter-item
  .tiers-drawer__filter-name:after {
  font-family: Icomoon;
  color: #63666a;
  content: '\E93B';
  font-size: 0.6rem;
  padding-left: 11px;
}

.tiers-drawer .tiers-drawer__filter-wrapper .tiers-drawer__filter-item {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 1px;
  cursor: pointer;
}

.tiers-drawer .tiers-drawer__filter-wrapper .tiers-drawer__filter-color {
  border-radius: 1px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.tiers-drawer .tiers-drawer__filter-wrapper .tiers-drawer__filter-name {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.6875rem;
  line-height: 1.03125rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__table {
  margin: 16px 0;
}

.tiers-drawer .tiers-drawer__table .benefits__title {
  font-weight: 700;
  font-family: Poppins, sans-serif;
  font-size: 1.7rem;
  line-height: 1.5rem; /* letter-spacing:0; */
  color: #292929;
}

.tiers-drawer .tiers-drawer__table-row {
  padding: 24px 0;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tiers-drawer .tiers-drawer__table-row > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.tiers-drawer .tiers-drawer__table-row:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}

.tiers-drawer .tiers-drawer__table-row .benefit__title {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.21875rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__table-row .benefit__title a {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.21875rem;
  letter-spacing: 0;
  color: #292929;
  text-decoration: none;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers {
  margin-top: 8px;
  padding-left: 12px;
  display: flex;
  justify-content: flex-start;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier.hidden {
  visibility: hidden;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-color {
  border-radius: 1px;
  width: 20px;
  height: 20px;
  display: block;
  margin-bottom: 20px;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-name {
  display: none;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.875rem;
  line-height: 1.03125rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-value {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.8125rem;
  line-height: 1.21875rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-value.icon-check:before {
  font-size: 0.75rem;
  font-family: 'costaglyph';
  content: '\e1947';
  color: #00a383;
}

.tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-value.icon-x:before {
  font-size: 0.75rem;
  font-family: 'costaglyph';
  content: '\e1982';
  color: #e0e0e0;
}

.tiers-drawer .tiers-drawer__notes {
  margin: 40px 0 32px;
}

.tiers-drawer .tiers-drawer__notes .tiers-drawer__notes-title {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: 0;
  color: #292929;
  margin-bottom: 8px;
}

.tiers-drawer .tiers-drawer__notes .tiers-drawer__notes-list {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.6875rem;
  line-height: 1.03125rem;
  letter-spacing: 0;
  color: #292929;
  margin-bottom: 24px;
  list-style: none;
}

.tiers-drawer .tiers-drawer__notes .tiers-drawer__notes-list strong {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.6875rem;
  line-height: 1.03125rem;
  letter-spacing: 0;
}

.tiers-drawer .tiers-drawer__notes .tiers-drawer__notes-generic {
  font-weight: 400;
  font-family: Poppins, sans-serif;
  font-size: 0.6875rem;
  line-height: 1.03125rem;
  letter-spacing: 0;
  color: #292929;
}

.tiers-drawer .tiers-drawer__notes .tiers-drawer__notes-generic > p a {
  color: #292929;
  text-decoration: underline;
  cursor: pointer;
}

.react-component.tiersDrawer {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .tiers-drawer__drawer-component .drawer-panel-close {
    right: 66px;
  }
  .tiers-drawer__drawer-component .drawer-panel-close:before {
    font-size: 0.625rem;
  }
  .tiers-drawer {
    padding: 0 48px;
  }
  .tiers-drawer .tiers-drawer__filter-bar {
    margin: 24px -32px 0;
    padding: 0 32px 16px;
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__title a {
    font-weight: 400;
    font-family: Poppins, sans-serif;
    font-size: 0.8125rem;
    line-height: 1.21875rem;
    letter-spacing: 0;
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__tiers {
    margin-top: 3px;
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-color {
    margin-bottom: 4px;
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier {
    margin-left: 2px;
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__tiers .benefit__tier-name {
    display: block;
    margin-bottom: 12px;
  }
}

@media (min-width: 981px) {
  .tiers-drawer__drawer-component .drawer-panel-close {
    right: 33px;
  }
  .tiers-drawer__drawer-component .drawer-panel-content {
    padding-top: 0;
  }
  .tiers-drawer {
    padding: 0 32px;
  }
  .tiers-drawer .tiers-drawer__table-row > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% + 16px);
    flex: 0 0 calc(50% + 16px);
  }
  .tiers-drawer .tiers-drawer__table-row .benefit__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
  }
}
