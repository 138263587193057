.cck {
  &-main {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    // padding-left: 15px;
    // padding-right: 15px;
  }

  &--cards-layout {
    margin-top: -70px;
  }
}

/* BODY END */

.page-title {
  label {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.85em;
  }

  h1.h1 {
    margin-top: 5px;
  }
}

//NEW
.page-title {
  &__pre-title {
    color: $gray-600;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.85em;
  }

  &__title {
    color: $base-blue;
    margin-top: 5px;
  }
}

.page-info {
  margin-top: 20px;
  @media only screen and (min-width: 768px) {
    margin-top: 30px;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 40px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 50px;
  }
}

.agosAddress {
  margin-top: 10px;
  line-height: 140%;
  font-style: normal;

  h4 {
    font-size: 1.3333333333333333em;
  }
}

.numberedText {
  padding-left: 35px;
  position: relative;

  .numberCircle {
    background: $dark-blue;
    color: #fff;
    border-radius: 0;
    height: 21px;
    width: 21px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-style: italic;
    position: absolute;
    left: 5px;
  }
}

.brand-container {
  height: 100px;

  &:not(:first-child) {
    margin: 50px 0;
  }

  &__logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    content: ' ';
    display: block;
    &--costa {
      background-image: url('/Style%20Library/CostaClickNew/images/logos/costa_logo_2021.png');
    }
    &--agos {
      background-image: url('/Style%20Library/CostaClickNew/images/logos/agos_logo_2.png');
      height: 55px;
    }
  }
}

.adv-logo {
  height: 125px;
  border: 2px solid #cecece;
  border-radius: 0;
  padding: 2px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  &__image-wrapper {
    padding: 15px;

    &__logo {
      height: 52px;
      background: no-repeat center;
      background-size: contain;
    }
  }
}

.costaclub-logo {
  background-image: url('/Style%20Library/CostaClickNew/images/cclub/club-logo-blue.jpg');
  content: ' ';
  background-position: 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  height: 96px;
  width: 93px;
  margin: 0px 5px 0px 0px;
  padding: 20px;

  > input[type='checkbox'] {
    position: absolute;
    top: 0px;
    left: 140px;
  }

  .cclub-restaurant-info .text__info b {
    font-size: 125%;
    color: #0071a3;
  }
}
