@import 'src/styles/variables';

.aside-wrapper {
  position: sticky;
  top: 0;
}
.c-aside {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: 0;
  padding: 20px;
  margin-bottom: 20px;

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid $border-color;
  }

  b {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  &__header {
    margin-bottom: 20px;
  }

  &__body {
    margin-bottom: 20px;

    .c-cruise-map {
      width: auto;
      flex-flow: row nowrap;
      flex-basis: auto;
      border: none;

      &__img {
        flex: 0 0 103px;
        width: 103px;
        height: 103px;
        border: 1px solid $border-color;
        border-radius: 0;
      }

      &__harbours {
        li {
          margin-bottom: 10px;
          &:last-child {
            &:after {
              top: -90%;
            }
          }
        }

        b {
          font-size: 13px;
        }

        span {
          color: $gray-600;
        }
      }
    }
  }

  &__footer {
    .fleet-shorex {
      label {
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
        color: $gray-600;
        font-size: 13px;
      }
    }
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    color: #000;
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 600;
  }

  &__sub {
    font-size: 13px;
    display: block;
    color: $gray-600;

    &--price {
      color: $blue;
    }
  }

  &__price {
    font-family: 'Poppins', sans-serif;
    color: $blue;
    font-size: 26px;
    margin-right: 4px;
    font-weight: 500;
  }

  &__currency {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: $blue;
    margin-right: 4px;
  }

  &__price-info {
    color: #000;
    opacity: 0.5;
    font-size: 22px;
    cursor: pointer;
  }

  .row-prices {
    margin-bottom: 7px;
  }

  &-details {
    padding: 20px;
    margin: 0 -20px -20px;
    background-color: #f9f9f9;
    border-top: 1px solid $border-color;
    cursor: pointer;
    user-select: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &__infos {
      background-color: #f9f9f9;
      padding: 20px;
      margin: 0 -20px -20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      section:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    &__section-title {
      display: block;
      color: $gray-600;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    &__section-declaration {
      font-size: 13px;
      color: #000;
      line-height: 1.2;
    }
  }

  .card-package__badge {
    display: none;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .c-aside-packs {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    width: 100%;
    list-style: none;
    padding: 16px 13px;
    margin-bottom: 0;
    margin-top: 10px;

    &__icon {
      height: 18px;
      @media only screen and (min-width: 1200px) {
        height: 21px;
      }

      &-discount {
        position: relative;
        top: -6px;
        right: 7px;
        color: $green;
      }

      &-wrapper {
        position: relative;

        &.not-inc {
          .c-aside-packs__icon {
            opacity: 0.3;
          }

          .c-aside-packs__icon-discount {
            color: $gray-600;
          }
        }
      }
    }
  }

  .progress::after {
    content: '_';
    color: white;
    width: 10px;
    height: 25px;
    position: absolute;
    left: 32px;
    font-size: 30px;
    border-left: 1px dashed #5b5b5b;
    top: 20px;
  }
  .progress::before {
    content: '_';
    color: white;
    width: 10px;
    height: 25px;
    position: absolute;
    left: 10px;
    font-size: 30px;
    border-left: 1px solid #5b5b5b;
    top: 20px;
  }

  .costaclub-logo-img {
    background-image: url('/Style%20Library/CostaClickNew/images/cclub/cclub-logo.jpg');
    width: 71px;
    height: 71px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .cclub {
    &__heading {
      text-align: right;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 13px;
      color: #014f75;
    }
    &__profile-badge {
      border: 2px solid #63666a;
      border-radius: 100%;
      width: calc(100% / 4.3);
      margin-left: 4px;
      padding-left: 4px !important;
      background: #f5f5f5;
      color: #63666a;
      height: 56px;
      padding-top: 3px !important;

      &--cblu {
        border-color: #014f75;
      }

      &--csilver {
        border-color: #bdbbbc;
      }

      &--cbronze {
        border-color: #c57b30;
      }

      &--cgold {
        border-color: #bd9535;
      }

      &--cplatinum {
        border-color: #717578;
      }

      &--initials {
        font-size: 32px;
      }
      &--name {
        font-weight: 800;
        font-size: 120%;
      }
    }
    &__extra-logo {
      height: 56px;
      width: 55px;
      background-size: contain;
      &--cblu {
        background-image: url('/Style%20Library/CostaClickNew/images/cclub/cblu.jpg');
      }
      &--csilver {
        background-image: url('/Style%20Library/CostaClickNew/images/cclub/csilver.jpg');
      }
      &--cbronze {
        background-image: url('/Style%20Library/CostaClickNew/images/cclub/cbronze.jpg');
      }
      &--cgold {
        background-image: url('/Style%20Library/CostaClickNew/images/cclub/cgold.jpg');
      }
      &--cplatinum {
        background-image: url('/Style%20Library/CostaClickNew/images/cclub/cplatinum.jpg');
      }
    }
  }
}
