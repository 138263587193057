/*
.info-tickets {
  .panel-footer {
    background-color: #FFF;
    padding: 8px 15px;
    .cta-wrapper {
      margin: 0;
    }
  }
}
*/
.cruises-price-wrapper {
  .complex-table-row {
    //border: 0;
  }
}