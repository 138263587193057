.icon-facility {
  font-size: 1.538em; //20px
  vertical-align: middle;
  &.icon-bordered {
    background-color: #fff;
    border: 1px solid $medium-blue;
    padding: 2px;
  }
}

.icon-deck-legend {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-right: 7px;
  &--cabine_con_vista_parziale {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/cabine_con_vista_parziale.png');
  }
  &--cabine_senza_vista {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/cabine_senza_vista.png');
  }
  &--cabine_per_disabili {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/cabine_per_disabili.png');
  }
  &--cabine_comunicanti {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/cabine_comunicanti.png');
  }
  &--ascensore {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/ascensore.png');
  }
  &--1_letto_alto {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/1_letto_alto.png');
  }
  &--divano_letto_singolo {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/divano_letto_singolo.png');
  }
  &--2_letti_bassi_non_convertibili {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/2_letti_bassi_non_convertibili.png');
  }
  &--1_letto_matrimoniale_non_convertibile {
    background-image: url('/Style%20Library/CostaClickNew/images/legenda_ponti/costa/1_letto_matrimoniale_non_convertibile.png');
  }
}

.shorexPassepartour {
  display: flex;
  flex-flow: row wrap;
  &__icon {
    font-family: 'CostaOTO';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 27px;
    width: 27px;
    border: 1px solid $base-blue;
    background: $base-blue;
    border-radius: 0;
    font-size: 1.2em;
    font-style: normal;
    margin-right: 5px;
    &:before {
      content: '\e924';
      color: $white;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.disabled {
    .shorexPassepartour {
      &__icon {
        opacity: 0.25;
        border: 1px solid $base-gray;
        background: $base-gray;
      }
    }
  }
}
