.popover {
  &--costa {
    font-family: 'Poppins', sans-serif;
    border: 0;
    z-index: 1040;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.15);
    text-align: center;
    color: #000;
    max-width: 250px;
    &.bottom {
      > .arrow {
        border: none;
        &:after {
          border-bottom-color: $base-blue;
        }
      }
    }
    &.top {
      > .arrow {
        border: none;
        &:after {
          border-top-color: $white;
        }
      }
    }
    .popover-header {
      background-color: transparent;
      border-bottom: none;
      text-transform: uppercase;
      color: $gray-600;
      padding: 8px 14px 0px;
    }
  }
}
