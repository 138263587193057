.card-details {
  .partial-container {
    > .card-header {
      border-bottom: 1px solid #d3d3d3;
      box-shadow: inset 10px 0 0 $primary, inset 12px 0 0 #fff;
      color: #fff;
      padding-left: 1.5rem;
    }
  }

  > .card-header {
    border-bottom: 1px solid #d3d3d3;
    box-shadow: inset 10px 0 0 $primary, inset 12px 0 0 #fff;
    color: #fff;
    padding-left: 1.5rem;
    border-radius: 0;
    .card-title {
      // font-size: 1.385em;
      max-width: 60%;
    }
  }

  .card-header {
    display: flex;
    align-items: center;

    .btn,
    .ng-select .ng-select-container {
      border-color: #fff;
    }

    .dropdown-toggle {
      padding: 9px 30px 9px 15px;
      border-color: $white;
      border-radius: 0;
    }
  }
}

.card-locked {
  > .card-body,
  > .list-group-flush {
    max-height: 35vh;
    overflow: auto;
    position: relative;
  }
}
