.form-control {
  &--5rows {
    height: 120px;
    width: 100%;
  }
  &--3rows {
    height: 75px;
    width: 100%;
    border-radius: 0;
  }
  &--no-resize {
    resize: none;
  }
  &.input-uppercase {
    text-transform: uppercase;
  }
  &--no-label {
    margin-top: 0;
    @media only screen and (min-width: 992px) {
      margin-top: 18px;
    }
  }
  &--is-small {
    font-size: 80%;
    color: $grey-placeholder;
  }
  &--sm {
    height: 34px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
}

.form-age {
  .age-block {
    position: relative;
    width: 90px;
  }
}
.age-wrapper {
  display: flex;
  flex-flow: column wrap;
  height: 140px;
}

.input-group {
  margin-bottom: 15px;
  span {
    &.input-group-btn {
      > .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        //padding: 7.5px 8px;
        z-index: 2;
        // height: 38px;
        &:before {
          border-radius: 0;
        }
        .icon {
          font-size: 1.538em; //20px
          margin-top: -4px;
        }
      }
    }
  }
  &.input-group-addon {
    .form-control-feedback {
      right: 14px;
      top: unset;
      bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      border-radius: 0;
      height: 34px;
    }
    &--no-label {
      .form-control-feedback {
        bottom: unset;
        top: 8px;
      }
    }
  }
  &--age {
    display: flex;
    max-width: 270px;
    margin-bottom: 0;
  }
}

.input-group {
  .input-group--age {
    &__input {
      display: inline-block;
      padding: 0;
      border: 0;
      margin: 0;
      background: none;
      &:nth-child(1) {
        width: 30%;
        input {
          border-radius: 0;
        }
        .form-control-feedback {
          right: 3px;
          top: 2px;
          border-radius: 0;
        }
      }
      &:nth-child(2) {
        width: 30%;
        input {
          border-radius: 0;
          margin-left: -1px;
        }
        .form-control-feedback {
          right: 4px;
          top: 2px;
          border-radius: 0;
        }
      }
      &:nth-child(3) {
        width: 40%;
        input {
          border-radius: 0;
          margin-left: -2px;
        }
        .form-control-feedback {
          right: 4px;
          top: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          border-radius: 0;
        }
      }
    }
  }
}

.cbx {
  margin: auto;
  user-select: none;
  cursor: pointer;
  &--blue {
    span {
      &:first-child {
        border: 2px solid #0071a3;
        &:before {
          background: #0071a3;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #149eff;
        }
      }
    }
  }
  &--purple {
    span {
      &:first-child {
        border: 2px solid #9013fe;
        &:before {
          background: #9013fe;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #c775fe;
        }
      }
    }
  }
  &--yellow {
    span {
      &:first-child {
        border: 2px solid #fed230;
        &:before {
          background: #fed230;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #feea8d;
        }
      }
    }
  }
  &--coral {
    span {
      &:first-child {
        border: 2px solid #f38281;
        &:before {
          background: #f38281;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #f38281;
        }
      }
    }
  }
  &--green {
    span {
      &:first-child {
        border: 2px solid #01c389;
        &:before {
          background: #01c389;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #01c389;
        }
      }
    }
  }
  &--lightgreen {
    span {
      &:first-child {
        border: 2px solid #dbf1d9;
        &:before {
          background: #dbf1d9;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: #dbf1d9;
        }
      }
    }
  }

  &--green1 {
    span {
      &:first-child {
        border: 2px solid $color-green1;
        &:before {
          background: $color-green1;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green1;
        }
      }
    }
  }
  &--green2 {
    span {
      &:first-child {
        border: 2px solid $color-green2;
        &:before {
          background: $color-green2;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green2;
        }
      }
    }
  }
  &--green3 {
    span {
      &:first-child {
        border: 2px solid $color-green3;
        &:before {
          background: $color-green3;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green3;
        }
      }
    }
  }
  &--green4 {
    span {
      &:first-child {
        border: 2px solid $color-green4;
        &:before {
          background: $color-green4;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green4;
        }
      }
    }
  }
  &--green5 {
    span {
      &:first-child {
        border: 2px solid $color-green5;
        &:before {
          background: $color-green5;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green5;
        }
      }
    }
  }
  &--green6 {
    span {
      &:first-child {
        border: 2px solid $color-green6;
        &:before {
          background: $color-green6;
        }
      }
    }
    &:hover {
      span {
        &:first-child {
          border-color: $color-green6;
        }
      }
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
    &:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 0;
      transform: scale(1);
      vertical-align: middle;
      transition: all 0.2s ease;
      margin-right: 5px;
      svg {
        position: absolute;
        top: 2px;
        left: 1px;
        fill: none;
        stroke: #ffffff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 0;
      }
      &:last-child {
        padding-left: 8px;
      }
    }
  }
}

.inp-cbx:checked {
  + .cbx--blue span:first-child {
    background: #0071a3;
    border-color: #0071a3;
    animation: wave 0.4s ease;
  }
  + .cbx--purple span:first-child {
    background: #9013fe;
    border-color: #9013fe;
    animation: wave 0.4s ease;
  }
  + .cbx--yellow span:first-child {
    background: #fed230;
    border-color: #fed230;
    animation: wave 0.4s ease;
  }
  + .cbx--coral span:first-child {
    background: #f38281;
    border-color: #f38281;
    animation: wave 0.4s ease;
  }
  + .cbx--lightgreen span:first-child {
    background: #dbf1d9;
    border-color: #dbf1d9;
    animation: wave 0.4s ease;
  }
  + .cbx--green span:first-child {
    background: #01c389;
    border-color: #01c389;
    animation: wave 0.4s ease;
  }
  + .cbx--green1 span:first-child {
    background: $color-green1;
    border-color: $color-green1;
    animation: wave 0.4s ease;
  }
  + .cbx--green2 span:first-child {
    background: $color-green2;
    border-color: $color-green2;
    animation: wave 0.4s ease;
  }
  + .cbx--green3 span:first-child {
    background: $color-green3;
    border-color: $color-green3;
    animation: wave 0.4s ease;
  }
  + .cbx--green4 span:first-child {
    background: $color-green4;
    border-color: $color-green4;
    animation: wave 0.4s ease;
  }
  + .cbx--green5 span:first-child {
    background: $color-green5;
    border-color: $color-green5;
    animation: wave 0.4s ease;
  }
  + .cbx--green6 span:first-child {
    background: $color-green6;
    border-color: $color-green6;
    animation: wave 0.4s ease;
  }
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
