@import 'partials/details-breadbrumbs';
@import 'partials/price-details';
@import 'partials/cruise-info';
@import 'partials/price-details-table';

.banner {
  margin-top: 20px;
}
.row-additional-info {
  flex-flow: column wrap;
}
.btn-additional-services {
  margin-bottom: 15px;
}
.cabin-section {
  .nav-tabs {
    border-bottom: 0;
  }
}
.special-services-description {
  &:first-line {
    color: $medium-blue;
    font-weight: 600;
  }
}

@import 'partials/trasportation';
@import 'partials/aside-details';

.row-costaclub {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: #d9eaf1;
  padding-top: 10px;
  + .row-costaclub-text {
    margin-bottom: 25px;
  }
}

.cta-wrapper {
  margin: 15px 0 30px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .btn-wrapper {
    &:not(:last-child) {
      margin-right: 4px;
    }
    &.push-right {
      margin-left: auto;
    }
    &.push-left {
      margin-right: auto;
    }
  }
  .btn-costa {
    min-width: auto;
    &:not(:first-child) {
      margin-left: auto;
    }
    @media only screen and (min-width: 992px) {
      &:not(:first-child) {
        margin-left: 0px;
      }
    }
    &:not(:last-child) {
      margin-right: 4px;
    }
    &.push-right {
      margin-left: auto;
    }
    &.push-left {
      margin-right: auto;
    }
  }
  + .list-inline {
    margin-top: -15px;
  }
}

.checkbox-facility {
  margin-top: 0;
  margin-bottom: 18px;
}

.facility-wrapper {
  display: flex;
  gap: 0.25rem;
  padding: 5px 0;
}

.panel-cabin {
  .panel-heading {
    display: flex;
  }
  .table-head-container {
    margin-left: auto;
    width: 220px;
    @media only screen and (min-width: 992px) {
      width: 305px;
    }
    @media only screen and (min-width: 1200px) {
      width: 392px;
    }
    span {
      display: inline-block;
      width: 105px;
      @media only screen and (min-width: 992px) {
        width: 140px;
      }
      @media only screen and (min-width: 1200px) {
        width: 150px;
      }
    }
  }
  .panel-body {
    padding: 0 15px;
    min-height: 42px;
  }
}

.cck-page .new-deck-selector .panel-cabin .panel-body {
  padding: 0 15px;
  min-height: 122px;
}

.cck-page .new-deck-selector .row-cabin-category {
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center; /* border-bottom:1px solid #f6f6f6; */
  border-top: 0;
  height: 184px;
  white-space: nowrap;
}

.row-cabin-category {
  flex-flow: row wrap;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  border-top: 0;
  height: 42px;
  white-space: nowrap;
  .row-fares-price {
    align-items: center;
    > [class*='col-'] {
      &:not(:first-child) {
        padding-left: 5px;
        padding-right: 5px;
      }
      &:first-child {
        padding-right: 5px;
        padding-left: 15px;
      }
      &:last-child {
        padding-left: 5px;
        padding-right: 15px;
        &:first-child {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    .price-container {
      display: flex;
      align-items: center;
    }
    .radio-inline {
      margin-top: -7px;
      @media only screen and (min-width: 1200px) {
        margin-top: -2px;
      }
    }
    .cabin-fares {
      .fares-wrapper.fares-wrapper--size-md {
        width: 52px;
        height: 20px;
        @media only screen and (min-width: 1200px) {
          width: 80px;
          height: 25px;
        }
      }
    }
  }
  &.row-special-services {
    white-space: normal;
    height: auto;
    padding: 5px 0;
    font-size: 0.85em;
    @media only screen and (min-width: 1200px) {
      font-size: 0.9em;
    }
    .row-actions {
      align-items: center;
      margin-top: 0;
    }
  }
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:nth-child(odd) {
    background-color: #fff;
  }
  &.row-cabin-category-header {
    color: $medium-grey;
    font-size: 11px;
  }
  .row-price {
    flex-flow: row wrap;
  }
  .col-cabin-price {
    padding-right: 15px;
    @media only screen and (min-width: 1200px) {
      padding-right: 0;
    }
  }
  .icon-wrapper {
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-availability {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 14px;
      width: 14px;
      border-radius: 0;
      color: #fff;
      font-size: 0.8em;
      &.available {
        background: linear-gradient(180deg, #b4ec51 0%, #429321 100%);
      }
      &.not-available {
        background: linear-gradient(180deg, #f5515f 0%, #9f041b 100%);
      }
    }
  }
  .cabin-title {
    white-space: normal;
    &:nth-child(even) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: #f9f9f9);
    }
    &:nth-child(odd) {
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: #fff);
    }
  }
  .cabin-group {
    font-size: 0.9em;
    white-space: nowrap;
    font-weight: 600;
    color: #858585;
    text-shadow: 0 1px 0 #fff;
    margin-left: -10px;
    @media only screen and (min-width: 992px) {
      margin-left: 0;
    }
  }
  .cabin-price {
    .price-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-top: 3px;
      @media only screen and (min-width: 992px) {
        margin-top: 0;
      }
      .pts-club {
        width: 80px;
        height: 20px;
        padding: 0px;
        font-weight: 700;
        color: #333333;
        text-align: center;
      }
      .aDa {
        font-size: 11px;
        font-weight: 600;
        color: $dark-grey;
        margin: 0 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .price {
        font-size: 1.1em;
        line-height: 160%;
      }
    }
  }
  .fares-image {
    width: 20px;
    @media only screen and (min-width: 1200px) {
      width: 100%;
    }
  }
  .radio-wrapper {
    padding-left: 0;
  }
}

.promo-flight-modal {
  .modal-lg {
    @media only screen and (min-width: 768px) {
      width: 750px;
    }
    @media only screen and (min-width: 992px) {
      width: 800px;
    }
    @media only screen and (min-width: 1200px) {
      width: 900px;
    }
  }
}

// .step-5 {
.info-booking {
  background-color: #fff;
  padding: 5px 15px 15px;
  margin-bottom: 15px;
  border: 1px solid $light-grey;
  border-top: 0;
  box-shadow: $box-shadow;
}

.info-intro {
  font-size: 1.1em;
  line-height: 170%;
  margin-bottom: 15px;
  .fares-container {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.info-customer-records {
  .row-age {
    flex-flow: row nowrap;
    justify-content: space-between;
    > input.form-control {
      width: 65px;
      &:not(:last-child) {
        width: 52px;
      }
    }
  }

  .row-add-documents {
    flex-flow: row nowrap;
    align-items: center;
    .col-xs-10 {
      padding-right: 5px;
    }
  }
}

// }

.anagrafica {
  padding-top: 15px;
}

.title-icon-wrapper {
  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .text {
      display: flex;
      flex-flow: column nowrap;
      margin-left: 7px;
    }
  }
}

.row-special-services-table {
  .nav-tab-container {
    .tab-pane {
      padding: 0 15px;
    }
  }
}

.special-services-card {
  border: 1px solid $light-grey;
  border-radius: 0;
  overflow: hidden;
  box-shadow: $box-shadow;
  background: #fff;
  margin: 0 0 10px;
  .title-container {
    h4.h4 {
      font-size: 13px;
      line-height: 120%;
      &:first-line {
        color: $base-blue;
        font-weight: 600;
        font-size: 15px;
        line-height: 180%;
      }
    }
  }
  .code-wrapper {
    background: $light-grey;
    width: 120px;
    float: left;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    font-weight: 600;
  }
  .info-wrapper {
    [class*='col-'] {
      min-height: 55px;
    }
  }
  textarea {
    resize: none;
    height: 100px;
  }
}

.iframe-cabin-position {
  iframe {
    height: 360px;
  }
}

.tours-icon {
  height: 22px;
  width: 22px;
  border-radius: 22px;
  display: inline-block;
  background: url('/Style%20Library/CostaClickNew/images/logos/mycosta-icon.svg') no-repeat 0 0 #fff;
  background-size: cover;
}

.promo-tag {
  display: inline-block;
  background: $green;
  padding: 5px 15px;
  border-radius: 0;
  float: left;
  &__text {
    color: $white;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    font-weight: 800;
  }
}

.fares-description {
  padding-top: 10px;
  .fares-wrapper {
    margin-bottom: 10px;
  }
}

.financial-cta {
  position: relative;
  padding-right: 0;
  .icon {
    position: absolute;
    right: -25px;
    top: 3px;
  }
}

.financial-acconto {
  border-bottom: 2px solid #fff;
  display: inline-block;
  width: 165px;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 5px;
  font-weight: bold;
}

.financial-card-wrapper {
  display: flex;
  flex-flow: column wrap;
  margin: 20px 0 0 0;
  @media (min-width: 992px) {
    flex-flow: row wrap;
  }
}

.financial-card {
  background: #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4), 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  height: 200px;
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 15px;
}

.financial-card-hgroup {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 8px;
  flex-basis: 52px;
  width: 100%;
  &__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    color: #0071a3;
    text-align: center;
  }
  &__subTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    color: text;
    text-align: center;
    padding-bottom: 8px;
  }
}

.financial-card-content {
  display: flex;
  flex-flow: column;
  flex-basis: 100px;
  width: 100%;
  margin-top: 10px;
  &__intro {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    line-height: 1.2;
    color: #text;
    text-align: center;
    padding-bottom: 5px;
  }
  &__value {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: #063c5d;
    text-align: center;
  }
  &__rate,
  &__tan {
    @extend .financial-card-content__value;
    text-transform: uppercase;
  }
  &__rate {
    margin-top: auto;
  }
  &__cta {
    @extend .financial-card-content__value;
    margin-top: auto;
    a {
      font-weight: 800;
      font-size: 12px;
      font-style: italic;
    }
    i {
      line-height: 1em;
    }
  }
  &__list {
    padding-left: 15px;
    text-align: left;
    margin-bottom: 0;
  }
}
