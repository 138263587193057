/* *
   * Andrea Calabrò - WebDesigner OfferteTourOperator 2017
 */

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
  @supports not (display: -webkit-box) {
	  &:before {
	    content: '...';
	    position: absolute;
	    right: 0;
	    bottom: 0;
	  }
	  &:after {
	    content: '';
	    position: absolute;
	    right: 0;
	    width: 1em;
	    height: 1em;
	    margin-top: 0.2em;
	    background: $bgColor;
	  }
  }
}
