.cookie {
  z-index: 1000;
  position: fixed;
  bottom: 25px;
  left: 25px;
  width: 520px;
  background: #EAEFF4;
  text-align: left;
  padding: 15px 20px;
  box-shadow: 1px 1px 18px rgba(0, 0, 0, 0.17);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 0;
  .cta-wrapper {
    margin: 10px 0 15px;
  }
}

