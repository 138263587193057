.summary-table {
  position: relative;
  top: -25px;
  &__row {
    display: flex;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid #D1CCCC;
    padding: 2px 0;
    margin-left: 0;
    margin-right: 0;
    min-height: 20px;
    &--header {
      border-bottom: 0;
      margin-bottom: 5px;
      min-height: auto;
    }
  }
  &__label {
    font-family: Poppins, "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #000;
    margin-right: 2px;
    white-space: nowrap;
  }
  &__data {}
  &__icon {
    font-size: 20px;
    color: $dark-blue;
    vertical-align: super;
  }
  &__col-1 {
    text-align: center;
  }
  &__col-7 {
    display: flex;
    flex-flow: row;
    padding-left: 0;
    + .summary-table__col-1 {
      margin-left: auto;
    }
  }
}