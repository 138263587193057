$card-padding: 20px;
$grid-gutter: 15px;
//$small: 480px;
$small: 0;
$medium: 768px;
$large: 992px;
$x-large: 1200px;

.shorex-row {
  height: 120px;
  display: flex;
  flex-flow: row nowrap;
  background: $white;
  border-radius: 0;
  margin-bottom: 15px;
  transition: transform 0.5s;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 0 5px 0 rgba(0, 0, 0, 0.2);
  @media (min-width: map-get($grid-breakpoints, sm)) {
    flex-basis: calc(100% - (#{$grid-gutter * 2} + #{$card-padding * 2}));
    margin: $grid-gutter;
  }
  &__data {
    display: flex;
    flex-flow: row nowrap;
    padding: 0;
  }
  &__info {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 15px;
  }
  &__actions {
  }
}

.shorex-row-picture {
  flex: 0 0 120px;
  border-bottom-left-radius: 0;
  background: no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon {
    color: #fff;
    box-shadow: $box-shadow;
    font-size: 3em;
    border-radius: 0;
    height: 39px;
    width: 32px;
    flex-basis: 32px;
  }
}

.shorex-row-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 85px;
  &__title {
    @include multiLineEllipsis($lineHeight: 1.4em, $lineCount: 2, $bgColor: white);
    font-size: 15px;
    font-weight: 600;
  }
  &__code {
  }
}

.shorex-row-tagbar {
  &__element {
    font-size: 11px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    //background-color: $medium-grey;
  }

  .myExplorationShorex {
    &-container {
      background-color: #ff9e28;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 3px 8px;
      color: #210c00;
      font-family: 'Poppins', sans-serif;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.85rem;
    }
  }

  .greenPass {
    &-container {
      background-color: #4ba244;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 3px 8px;
      color: white;
      font-family: 'Poppins', sans-serif;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.85rem;
    }

    &-logo {
      background-image: url('https://www.costaextra.it/Style%20Library/ModuleShipAndShorex/images/greenPassIcon.png');
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 0.25rem;
      &--small {
        @extend .greenPass-logo;
        width: 16px;
        height: 16px;
        padding: 8px 8px;
      }
    }
  }
}

.shorex-row-actions {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  &__price {
  }
  &__cta {
  }
}

.shorex-row-price {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  &__adult {
  }
  &__child {
  }
  &__declaration {
    color: $base-blue;
    font-size: 1.65em;
    font-weight: 600;
  }
  &__data {
    color: $medium-grey;
  }
}
